<template>
  <div
    :class="[
      'c-conversation-menu',
      {
        'c-conversation-menu--with-items': showTabsItems,
        'c-conversation-menu--resizing': menuWidth.resizing,
        'c-conversation-menu--custom-width': null !== menuWidth.custom,
        'c-conversation-menu--narrow':
          null !== menuWidth.custom && menuWidth.custom <= 300,
      },
    ]"
    :style="{
      width: (menuWidth.custom || menuWidth.default) + 'px',
    }"
  >
    <div class="c-conversation-menu__divider-overlay"></div>
    <div class="c-conversation-menu__inner">
      <div class="c-conversation-menu__tabs">
        <div class="c-conversation-menu__tabs-flex">
          <div style="flex: 1">
            <BaseIcon
              name="MagnifyingGlass"
              size="20px"
              color="#CCCCCC"
              class="c-conversation-menu__field-icon"
            />

            <input
              type="text"
              v-model="search.query"
              class="c-conversation-menu__field"
              placeholder="Pesquisar"
            />

            <BaseSpinner
              v-if="search.loading"
              size="small"
              class="c-conversation-menu__field-loading"
            />

            <BaseIcon
              v-else-if="search.query"
              name="close"
              size="20px"
              color="#CCCCCC"
              cursor="pointer"
              class="c-conversation-menu__field-icon--close"
              @click="clearSearchQuery"
            />
          </div>

          <div
            v-if="connected === false && loadingConnected === false"
            class="connection-status"
          ></div>
        </div>
      </div>

      <div class="c-conversation-menu__tabs">
        <div>
          <div v-if="!showAllContacts" class="c-conversation-menu__tabs-grid">
            <ul class="c-conversation-menu__list">
              <li
                :class="[
                  'c-conversation-menu__list-item',
                  { active: filterUnreadMessages === false },
                ]"
                @click="handleConversationFilter(false)"
              >
                Tudo
              </li>

              <li
                :class="[
                  'c-conversation-menu__list-item',
                  { active: filterUnreadMessages === true },
                ]"
                style="position: relative"
                @click="handleConversationFilter(true)"
              >
                Não lidas

                <div
                  v-if="conversationsUnreadCount?.count"
                  class="c-conversation-menu__list-unread"
                >
                  {{ conversationsUnreadCount?.count }}
                </div>
              </li>
            </ul>

            <!-- <div
              @click="showAllContacts = true"
              class="c-conversation-menu__icon-add"
            >
              <BaseIcon name="Plus" size="20px" color="#CCCCCC" />
            </div> -->
          </div>

          <div
            v-else
            style="
              display: flex;
              align-items: center;
              gap: 16px;
              cursor: pointer;
            "
            @click="showAllContacts = false"
          >
            <div class="c-conversation-menu__icon-add active">
              <BaseIcon name="Plus" size="20px" color="#CCCCCC" />
            </div>

            <span style="color: #ccc"> Inicie uma nova conversa </span>
          </div>
        </div>
      </div>

      <div v-if="!displaySearch" class="c-conversation-menu__body">
        <div class="c-conversation-menu__conversations">
          <RecycleScroller
            :items="conversations"
            :item-size="89"
            :buffer="windowHeight"
            key-field="contactID"
            page-mode=""
            v-slot="{ item }"
          >
            <SwipeOut
              :item="item"
              :threshold="100"
              is-new-class="c-conversation-menu-item--animate"
            >
              <ConversationMenuItem
                class="c-conversation-menu__conversation"
                v-on:click="onConversationClick"
                :active="activeSessionId === item.contactLastSessionID"
                :tenantID="tenantID"
                :sessionID="item.contactLastSessionID"
                :conversation="item"
                :conversation-channel="'whatsapp-secondary'"
                :conversation-availability="''"
                conversation-assigned-user=""
                :conversation-avatar-url="item.contactPhoto"
                :conversation-is-blocked="false"
                :conversation-is-mentioned="false"
                :conversation-last-message="
                  lastMessageProcessor(item._$?.lastMessage?.messageValue)
                "
                :conversation-nickname="item.contactFirstname"
                :conversation-is-new="false"
                :conversation-unread-operator="item._$.unreadCount"
                :conversation-operators="{}"
                :conversation-compose-visitor="{}"
                :conversation-compose-operator="{}"
                :key="item.contactID"
              />

              <!-- LEFT SWIPE ACTION -->
              <template v-slot:left>
                <div
                  class="c-conversation-menu__swipeout-action c-conversation-menu__swipeout-action--resolve swipeout-action"
                >
                  <div class="c-conversation-menu__swipeout-inner">
                    <BaseIcon
                      class="c-conversation-menu__icon"
                      name="inbox_action_resolve"
                      size="26px"
                    />
                  </div>
                </div>
              </template>

              <!-- RIGHT SWIPE ACTION -->
              <template v-slot:right>
                <div
                  class="c-conversation-menu__swipeout-action c-conversation-menu__swipeout-action--remove swipeout-action"
                >
                  <div class="c-conversation-menu__swipeout-inner">
                    <BaseIcon
                      class="c-conversation-menu__icon"
                      name="inbox_action_remove"
                      size="20px"
                    />
                  </div>
                </div>
              </template>
            </SwipeOut>
          </RecycleScroller>

          <VueInfiniteLoading
            class="c-conversation-menu__infinite-wrapper"
            @infinite="onLoadMoreConversations"
            ref="infiniteLoader"
          >
            <template v-slot:spinner>
              <BaseSpinner class="c-conversation-menu__infinite-loading" />
            </template>
            <template v-slot:no-more>
              <div></div>
            </template>
            <template v-slot:no-results>
              <div></div>
            </template>
          </VueInfiniteLoading>
        </div>

        <div
          v-if="conversations.length === 0"
          class="c-conversation-menu__information"
        >
          <div v-if="!isLoadingUnread && !loading">
            <BaseEmptySpot
              class="c-conversation-menu__empty-spot"
              :description="
                this.$t('INBOX.LIST.EMPTY_NO_CONVERSATION_DEFAULT_LABEL')
              "
              :title="this.$t('INBOX.LIST.EMPTY_NO_CONVERSATION_DEFAULT_TITLE')"
              illustration=""
              size="small"
            ></BaseEmptySpot>
          </div>
        </div>
      </div>

      <div v-else ref="scrollContainer" class="c-conversation-menu__body">
        <div v-show="!search.loading">
          <div
            class="c-conversation-menu__conversations"
            style="position: relative"
          >
            <h5 style="color: #ccc; margin: 20px 20px 0">Contatos</h5>

            <span
              v-if="results.contacts.data.length < 1"
              class="c-conversation-menu__conversations--text"
            >
              Nenhum resultado encontrado
            </span>

            <template v-if="results.contacts.data.length > 0">
              <RecycleScroller
                key-field="contactID"
                :item-size="89"
                :buffer="windowHeight"
                :items="results.contacts.data"
                page-mode=""
                v-slot="{ item }"
              >
                <SwipeOut
                  :item="item"
                  :threshold="100"
                  is-new-class="c-conversation-menu-item--animate"
                >
                  <ConversationMenuItem
                    class="c-conversation-menu__conversation"
                    v-on:click="onConversationClick"
                    :active="activeSessionId === item.contactLastSessionID"
                    :tenantID="tenantID"
                    :sessionID="item.contactLastSessionID"
                    :conversation="item"
                    :conversation-channel="'whatsapp-secondary'"
                    :conversation-availability="''"
                    conversation-assigned-user=""
                    :conversation-avatar-url="item.contactPhoto"
                    :conversation-is-blocked="false"
                    :conversation-is-mentioned="false"
                    :conversation-last-message="
                      lastMessageProcessor(item._$?.lastMessage?.messageValue)
                    "
                    :conversation-nickname="item.contactFirstname"
                    :conversation-is-new="false"
                    :conversation-unread-operator="item?._$?.unreadCount || 0"
                    :conversation-operators="{}"
                    :conversation-compose-visitor="{}"
                    :conversation-compose-operator="{}"
                    :key="item.contactID"
                  />

                  <template v-slot:left> </template>
                  <template v-slot:right> </template>
                </SwipeOut>
              </RecycleScroller>
            </template>

            <div
              ref="contactsInfiniteScroll"
              style="position: absolute; bottom: calc(89px * 2)"
            />
          </div>

          <div style="margin-top: 32px; position: relative">
            <h5 style="color: #ccc; margin: 0 20px">Mensagens</h5>

            <span
              v-if="results.messages.data.length < 1"
              class="c-conversation-menu__conversations--text"
            >
              Nenhum resultado encontrado
            </span>

            <ul class="c-conversation-menu__contacts">
              <li
                v-for="message in results.messages.data"
                :key="message"
                @click="onConversationClick(message.lastSessionID)"
              >
                <BaseAvatar
                  :avatar-id="message.id"
                  :avatar-url="message.avatar"
                  type="visitor"
                  size="medium"
                />

                <div>
                  <span style="font-weight: 700; color: #fff">
                    {{ message.title }}
                  </span>

                  <p
                    style="color: #fff; margin-bottom: 0"
                    v-html="
                      $filters.format.parseHighlight(
                        $filters.format.cutText(message.label, 100),
                        search.query,
                        ['c-spotlight-result__match', 'u-semibold'],
                        true,
                      )
                    "
                  />
                </div>
              </li>
            </ul>

            <div
              ref="messagesInfiniteScroll"
              style="position: absolute; bottom: calc(89px * 3)"
            />
          </div>
        </div>
      </div>

      <!-- REINVENT_CUSTOM_MODIFICATION -->
      <!-- Removed due unnecessary, conversation search already reach this purpose -->
      <!-- <ConversationMenuContacts v-else @onClose="showAllContacts = false" /> -->

      <ConversationMenuFooter
        class="c-conversation-menu__footer"
        v-on:createConversation="handlePopups('newConversation', true)"
        v-on:routingClick="onRoutingClick"
        :operators="onlineOperators"
        :tenantID="tenantID"
      />
    </div>

    <NewConversation
      v-if="popups.newConversation.visible"
      v-on:close="handleCloseNewConversation"
    />
  </div>
</template>
<script>
import mitt from "mitt";
import * as a from "vue-swipe-actions";
import debounce from "lodash.debounce";
import focus from "@/classes/talqui/focus";
import startsWith from "lodash.startswith";
import message from "@/classes/talqui/message";
import contact from "@/classes/talqui/contact";
import * as pluginsMixin from "@/mixins/plugins.js";
import BaseSpinner from "@/components/Reinvent/Base/BaseSpinner.vue";
import NewConversation from "@/components/Reinvent/Popus/NewConversation.vue";
import ConversationMenuItem from "@/components/Reinvent/Conversation/ConversationMenuItem.vue";
import ConversationMenuFooter from "@/components/Reinvent/Conversation/ConversationMenuFooter.vue";

const A = "custom_";
const v = 365;
const E = 295;
const k = 900;
const z = 15;

const emitter = mitt();

const WHATSAPP_PLUGIN_URN = "urn:talqui:whatsapp-secondary:0";

export default {
  components: {
    BaseSpinner,
    NewConversation,
    ConversationMenuItem,
    ConversationMenuFooter,
    // eslint-disable-next-line vue/no-unused-components
    SwipeOut: a.SwipeOut,
    // eslint-disable-next-line vue/no-unused-components
    SwipeList: a.SwipeList,
  },
  mixins: [pluginsMixin],
  data() {
    return {
      search: {
        query: "",
        initializing: false,
        loading: false,
      },
      loadingConnected: false,
      connected: false,
      results: {
        messages: { data: [], page: 1, hasMore: false, loading: false },
        contacts: { data: [], page: 1, hasMore: false, loading: false },
      },
      tenantID: null,
      activeSessionId: null,
      loading: false,
      acquireOnlineOperatorsScheduler: null,
      processComposeScheduler: null,
      initialStatesTimeout: null,
      resolveConversationTimeout: null,
      popups: {
        batchReadAll: {
          visible: false,
        },
        batchResolveAll: {
          visible: false,
        },
        newConversation: {
          visible: false,
        },
        newFilter: {
          visible: false,
          filter: null,
        },
        removeConversation: {
          visible: false,
          pendingConversation: null,
        },
        removeFilter: {
          visible: false,
          filterId: null,
        },
      },
      windowHeight: window.innerHeight,
      customFilterPrefix: A,
      menuWidth: {
        default: v,
        custom: null,
        ghost: null,
        resizing: false,
        snapped: false,
        lastDownX: null,
      },
      showTabsItems: false,
      actionPending: false,
      filterUnreadMessages: false,
      showAllContacts: false,
      observerContact: null,
      observerMessages: null,
      isLoadingUnread: false,
      currentPageUnread: 0,
    };
  },
  computed: {
    pluginUrns() {
      return this.$store.getters["pluginsTalqui/getPluginConnectionURNs"];
    },
    tabs() {
      var customs =
        this.pluginUrns.includes("urn:talqui:chatbot:0") ||
        this.pluginUrns.includes("urn:talqui:chatbot:1")
          ? {
              id: "auto",
              name: this.$t("TALQUI.INBOX.CONVERSATION_MENU.AUTO"),
            }
          : {};
      return [
        customs,
        {
          id: "queued",
          name: this.$t("TALQUI.INBOX.CONVERSATION_MENU.QUEUED"),
          notification: this.$store.getters[
            "notificationsTalqui/getNotification"
          ](this.tenantID, "queuedCount"),
        },
        {
          id: "mine",
          name: this.$t("TALQUI.INBOX.CONVERSATION_MENU.MINE"),
          notification: this.$store.getters[
            "notificationsTalqui/getNotification"
          ](this.tenantID, "manualPendingCount"),
        },
        {
          id: "filters",
          name: this.$t("TALQUI.INBOX.CONVERSATION_MENU.FILTERS"),
          groups: [
            {
              id: "internal",
              items: [
                {
                  id: "manual",
                  label: this.$t(
                    "TALQUI.INBOX.CONVERSATION_MENU.FILTER_ITENS.MANUAL",
                  ),
                },
                {
                  id: "inactive",
                  label: this.$t(
                    "TALQUI.INBOX.CONVERSATION_MENU.FILTER_ITENS.INACTIVE",
                  ),
                },
              ],
            },
            {
              id: "custom",
              items: [].concat([
                {
                  id: "new_filter",
                  label: this.$t("TALQUI.INBOX.CONVERSATION_MENU.LIST.CUSTOM"),
                  isAction: true,
                },
              ]),
            },
          ],
        },
      ];
    },
    currentTenant() {
      return (
        this.$store.getters["tenantsTalqui/getTenant"](this.tenantID) || {}
      );
    },
    activeFilter() {
      return this.$store.getters["inboxTalqui/getActiveFilter"];
    },
    activeSessionKind() {
      return this.$store.getters["inboxTalqui/getActiveSessionKind"];
    },
    onlineOperators() {
      this.$store.commit("operatorsTalqui/assertOperators", {
        tenantID: this.tenantID,
      });
      return [];
    },
    conversations() {
      return this.$store.getters["conversationsReinvent/getConversations"](
        this.tenantID,
      );
    },
    conversationsUnreadCount() {
      return this.$store.getters[
        "conversationsReinvent/getConversationsUnreadCount"
      ](this.tenantID);
    },
    operatorMe() {
      return this.$store.getters["operatorsTalqui/getMe"];
    },
    displaySearch() {
      return this.search.query !== "";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(e) {
        this.activeSessionId =
          "app.website.inbox.conversation.reinvent" === e.name
            ? e.params.sessionId
            : null;
        if (
          e.name.startsWith("app.website.inbox") &&
          e.params.tenantID &&
          this.tenantID !== e.params.tenantID
        ) {
          this.tenantID = e.params.tenantID;
          this.assertWebsite();
        }
      },
    },
    "search.query": {
      async handler() {
        if (this.search.query) {
          this.search.loading = true;
          await this.dispatchSearch(this.search.query);
        }
      },
    },
  },
  created() {
    if ((this.conversationMenuLayout || {}).width) {
      this.menuWidth.custom = this.conversationMenuLayout.width;
    }

    this.$store.dispatch(
      "conversationsReinvent/acquireConversationsUnreadCount",
      {
        tenantID: this.$route.params.tenantID,
      },
    );

    emitter.on("plugin:init", this.checkConnectionStatus);
    this.checkConnectionStatus();
  },
  beforeUnmount() {
    emitter.off("plugin:init", this.checkConnectionStatus);
    if (null !== this.acquireOnlineOperatorsScheduler) {
      clearInterval(this.acquireOnlineOperatorsScheduler);
      this.acquireOnlineOperatorsScheduler = null;
    }
    if (null !== this.processComposeScheduler) {
      clearInterval(this.processComposeScheduler);
      this.processComposeScheduler = null;
    }
    if (null !== this.initialStatesTimeout) {
      clearTimeout(this.initialStatesTimeout);
      this.initialStatesTimeout = null;
    }
  },
  checkConnectionStatus() {
    emitter.emit("plugin:init");
  },
  methods: {
    checkConnectionStatus() {
      this.loadingConnected = true;
      const pluginConnectionID =
        this.$store.getters["pluginsTalqui/getPluginConnection"](
          WHATSAPP_PLUGIN_URN,
        )?.pluginConnectionID;

      if (!pluginConnectionID) {
        return;
      }

      this.$store
        .dispatch("pluginsTalqui/pluginConnectionStatus", {
          tenantID: this.tenantID,
          pluginConnectionID: pluginConnectionID,
        })
        .then((response) => {
          this.connected = response.connected;
          this.loadingConnected = false;
        })
        .catch(() => {
          this.loadingConnected = false;
        });
    },
    clearSearchQuery() {
      this.search = {
        query: "",
        initializing: false,
        loading: false,
      };
      this.results = {
        messages: { data: [], page: 1, hasMore: false, loading: false },
        contacts: { data: [], page: 1, hasMore: false, loading: false },
      };
    },
    observeContactsInfiniteScroll() {
      this.observerContact = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (
                this.results.contacts.loading ||
                this.results.contacts.page === 1 ||
                !this.results.contacts.hasMore
              )
                return;

              this.onLoadMore("contacts");
            }
          });
        },
        { root: this.$refs.scrollContainer, threshold: 0.2 }, // threshold 1.0 significa que 100% do elemento precisa estar visível
      );

      this.observerContact.observe(this.$refs.contactsInfiniteScroll);
    },
    observeMessagesInfiniteScroll() {
      this.observerMessages = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (
                this.results.messages.loading ||
                this.results.messages.page === 1 ||
                !this.results.messages.hasMore
              )
                return;
              this.onLoadMore("messages");
            }
          });
        },
        { root: this.$refs.scrollContainer, threshold: 1.0 }, // threshold 1.0 significa que 100% do elemento precisa estar visível
      );

      this.observerMessages.observe(this.$refs.messagesInfiniteScroll);
    },
    startsWith: startsWith,
    dispatchSearch: debounce(async function (searchTerm) {
      const sanitizeSearchTerm = searchTerm.replaceAll(/[()\-]/g, "");

      const responses = await Promise.all([
        this.dispatchSearchMessages(sanitizeSearchTerm),
        this.dispatchSearchContacts(sanitizeSearchTerm),
      ]);

      Object.keys(this.results).forEach((type, index) => {
        this.applyTypeResults(
          type,
          responses[index],
          this.results[type].page + 1,
        );
      });

      this.search.loading = false;
    }, 1000),
    dispatchSearchMessages(searchTerm, page = 1) {
      if (searchTerm.length < 1) Promise.resolve(null);

      if (page === 1 && this.observerMessages) {
        this.observerMessages.unobserve(this.$refs.messagesInfiniteScroll);
      }

      return message
        .messageBase(this.tenantID)
        .get({
          page: page,
          perPage: 10,
          search: searchTerm,
        })
        .then((res) => {
          const hasMore = 206 === res.__status && true;
          if (page === 1 && hasMore) this.observeMessagesInfiniteScroll();

          /**
           * Necessary because when user clicks to open the conversation
           * we need to guarantee that last session was create on store
           */
          res.forEach((message) => {
            this.$store.commit("sessionsTalqui/storeSession", {
              tenantID: this.tenantID,
              sessionID: message.contact.contactLastSessionID,
              session: {
                contact: message.contact,
                contactID: message.contact.contactID,
              },
            });
          });

          return Promise.resolve({
            items: res.map((message) => ({
              id: message.messageID,
              title: message?.contact?.contactFirstname,
              label: message.messageValue.replace(/\n/giu, " "),
              avatar: message?.contact?.contactPhoto,
              timestamp: new Date(message.createdAt).getTime(),
              lastSessionID: message?.sessionID || null,
            })),
            has_more: 206 === res.__status && true,
          });
        })
        .catch(() => Promise.resolve(null));
    },
    dispatchSearchContacts(searchTerm, page = 1) {
      if (searchTerm.length < 1) return Promise.resolve(null);

      if (page === 1 && this.observerContact) {
        this.observerContact?.unobserve(this.$refs.contactsInfiniteScroll);
      }

      return contact
        .contacts(this.tenantID)
        .get({
          page: page,
          perPage: 10,
          search: searchTerm,
        })
        .then((res) => {
          const hasMore = 206 === res.__status;
          if (page === 1 && hasMore) this.observeContactsInfiniteScroll();

          /**
           * Necessary because when user clicks to open the conversation
           * we need to guarantee that last session was create on store
           */
          res.forEach((contact) => {
            this.$store.commit("sessionsTalqui/storeSession", {
              tenantID: this.tenantID,
              sessionID: contact.contactLastSessionID,
              session: {
                contact,
                contactID: contact.contactID,
              },
            });
          });

          return Promise.resolve({
            items: res,
            has_more: hasMore,
          });
        })
        .catch(() => Promise.resolve(null));
    },
    async onLoadMore(type) {
      let fecthFunction;

      switch (type) {
        case "messages":
          fecthFunction = this.dispatchSearchMessages;
          break;
        case "contacts":
          fecthFunction = this.dispatchSearchContacts;
          break;
      }

      const response = await fecthFunction(
        this.search.query,
        this.results[type].page,
      );

      const responseItemsConcated = {
        ...response,
        items: this.results[type].data.concat(response.items),
      };

      this.applyTypeResults(
        type,
        responseItemsConcated,
        this.results[type].page + 1,
      );
    },
    applyTypeResults(type, response, page = 1) {
      this.results[type].data = response?.items || [];
      this.results[type].page = response?.has_more ? page : 1;
      this.results[type].hasMore = response?.has_more || false;
    },
    handlePopups(popup, status) {
      this.popups[popup].visible = status;
    },
    handleConversationFilter(status) {
      this.filterUnreadMessages = status;

      this.$store.commit("conversationsReinvent/clearConversations", {
        tenantID: this.tenantID,
      });

      this.resetInfiniteLoading();

      this.filterUnreadMessages
        ? this.loadUnreadConversations(true)
        : this.loadMore(null, true, true);
    },
    handleCloseNewConversation() {
      this.popups.newConversation.visible = !1;
    },
    lastMessageProcessor(messageValue) {
      return typeof messageValue === "string" ? messageValue : "";
    },
    onRoutingClick(e) {
      this.$store.commit("inboxTalqui/setActiveSessionKind", {
        value: e,
      });
      this.currentPage = 0;
      this.resetInfiniteLoading();
      this.loadMore();
    },
    assertWebsite() {
      this.currentPage = 0;

      this.$store.commit("conversationsReinvent/assertConversations", {
        tenantID: this.tenantID,
      });

      this.resetInfiniteLoading();
      this.loadMore(null, true, true);
      this.bindListeners();
    },
    loadUnreadConversations(reset) {
      if (!this.isLoadingUnread) {
        this.isLoadingUnread = true;

        if (reset) this.currentPageUnread = 0;
        this.currentPageUnread++;

        if (this.currentPageUnread === -1) this.resetInfiniteLoading();

        return this.$store
          .dispatch("conversationsReinvent/acquireConversations", {
            tenantID: this.tenantID,
            page: this.currentPageUnread,
            filter: "unreadMessages",
          })
          .then((res) => {
            if (false === res.hasNext) {
              this.currentPageUnread = -1;
              this.completeInfiniteLoading();
            } else {
              this.completeInfiniteLoaded();
            }

            this.isLoadingUnread = false;
          });
      }
    },
    loadMore(e, t = false, i = true) {
      if (true !== this.loading) {
        if (t) this.currentPage = 0;

        this.currentPage++;
        this.loading = true === i;

        if (-1 === this.currentPage) {
          this.resetInfiniteLoading();
        }

        return this.$store
          .dispatch("conversationsReinvent/acquireConversations", {
            tenantID: this.tenantID,
            page: this.currentPage,
          })
          .then((res) => {
            if (res.hasNext === false) {
              this.currentPage = -1;
              this.completeInfiniteLoading();
            } else {
              this.completeInfiniteLoaded();
            }

            this.loading = false;
            return Promise.resolve();
          });
      }
    },
    onLoadMoreConversations() {
      this.filterUnreadMessages
        ? this.loadUnreadConversations()
        : this.loadMore();
    },
    bindListeners() {
      this.scheduleAcquireOnlineOperators();
    },
    scheduleAcquireOnlineOperators() {
      if (null !== this.acquireOnlineOperatorsScheduler) {
        clearInterval(this.acquireOnlineOperatorsScheduler);
      }

      this.acquireOnlineOperatorsScheduler = setInterval(() => {
        focus.purgeFocusActions("acquire_online_operators");
        focus.runInFocus("acquire_online_operators", () => {
          this.acquireOnlineOperators();
        });
      }, 300000);
    },
    acquireOnlineOperators() {
      this.$store.dispatch("operatorsTalqui/acquireOperators", {
        tenantID: this.tenantID,
      });
    },
    processCompose() {
      console.log("@processCompose");
    },
    resetInfiniteLoading() {
      this.$refs?.infiniteLoader &&
        this.$refs.infiniteLoader.stateChanger &&
        this.$refs.infiniteLoader.stateChanger.reset();
    },
    completeInfiniteLoading() {
      this.$refs?.infiniteLoader &&
        this.$refs.infiniteLoader.stateChanger &&
        this.$refs.infiniteLoader.stateChanger.complete();
    },
    completeInfiniteLoaded() {
      this.$refs?.infiniteLoader &&
        this.$refs.infiniteLoader.stateChanger &&
        this.$refs.infiniteLoader.stateChanger.loaded();
    },
    onConversationClick(e) {
      this.goToConversation(e);
    },
    toggleFilters(e = "auto") {
      if (e !== this.activeFilter) {
        this.$store.commit("inboxTalqui/setActiveFilter", {
          value: e,
        });
        this.currentPage = 0;
        this.resetInfiniteLoading();
        this.loadMore();
      }
    },
    onTabItemClick(e, tabID) {
      if (tabID.startsWith("custom_")) {
        console.log("FILTRO CUSTOMIZADO", tabID);
      } else {
        this.toggleFilters(tabID);
      }
    },
    onTabItemActionClick(e, t, i) {
      console.log(e, t, i);
    },
    onDividerMousedown(e) {
      this.menuWidth.lastDownX = e.clientX;
      this.menuWidth.ghost = this.menuWidth.custom;
      document.addEventListener("mousemove", this.onDividerMousemove);
      document.addEventListener("mouseup", this.onDividerMouseup);
    },
    onDividerMouseup() {
      this.menuWidth.resizing = false;
      this.menuWidth.lastDownX = null;
      this.menuWidth.ghost = this.menuWidth.custom;
      document.removeEventListener("mousemove", this.onDividerMousemove);
      document.removeEventListener("mouseup", this.onDividerMouseup);
    },
    onDividerMousemove(e) {
      const t = e.clientX - this.menuWidth.lastDownX;
      (this.menuWidth.resizing = true),
        (this.menuWidth.ghost =
          (this.menuWidth.ghost || this.menuWidth.default) + t),
        this.menuWidth.ghost >= this.menuWidth.default - z &&
        this.menuWidth.ghost <= this.menuWidth.default + z
          ? (null !== this.menuWidth.custom &&
              ((this.menuWidth.snapped = true),
              setTimeout(() => {
                this.menuWidth.snapped = false;
              }, 200)),
            this.setConversationMenuWidth(null))
          : this.menuWidth.ghost > E &&
            this.menuWidth.ghost < k &&
            this.setConversationMenuWidth(this.menuWidth.ghost),
        (this.menuWidth.lastDownX = e.clientX);
    },
    onDividerDoubleclick() {
      this.menuWidth.ghost = null;
      this.setConversationMenuWidth(null);
    },
    setConversationMenuWidth(e) {
      this.menuWidth.custom = e;
      this.$store.commit("inboxTalqui/setLayout", {
        item: "conversationMenu",
        key: "width",
        value: e,
      });
    },
    goToConversation(sessionID) {
      this.$router
        .push({
          name: "app.website.inbox.conversation.reinvent",
          params: {
            tenantID: this.tenantID,
            sessionId: sessionID,
          },
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.disable {
  pointer-events: none;
  opacity: 0.2;
}

.c-conversation-menu__contacts {
  margin-left: 0;
  li {
    min-height: 89px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(94, 94, 94, 0.15);
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.c-conversation-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  .c-conversation-menu__inner {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: rgba(243, 244, 244, 0.2);
    border-radius: 20px;
    .c-conversation-menu__tabs {
      padding: 0 20px;
      > div {
        padding: 20px 0;
        border-bottom: 1px solid rgba(94, 94, 94, 0.2);
        > div {
          position: relative;
          .c-conversation-menu__field-icon {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 20px;
          }
          .c-conversation-menu__field-icon--close {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 20px;
          }
          .c-conversation-menu__field-loading {
            position: absolute;
            top: 50%;
            transform: translate(0, -45%);
            right: -10px;
          }
          .c-conversation-menu__field {
            width: 100%;
            height: 40px;
            border: none;
            border-radius: 32px;
            padding-left: 48px;
            padding-right: 20px;
            outline: none;
            color: #fff;
            background-color: rgba(243, 244, 244, 0.2);
            position: relative;

            &::placeholder {
              color: #ccc;
            }
          }
        }
      }
      .c-conversation-menu__tabs-grid {
        display: grid;
        grid-template-columns: 1fr 40px;

        .c-conversation-menu__list {
          display: flex;
          list-style: none;
          margin: 0;
          display: flex;
          width: 203px;
          height: 44px;
          padding: 0px 4px;
          justify-content: center;
          align-items: center;
          gap: 3px;
          background: rgba(243, 244, 244, 0.2);
          border-radius: 32px;
        }

        .c-conversation-menu__list-item {
          flex: 1 0 0;
          height: 36px;
          padding: 0px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          color: #757576;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &.active {
            color: #fff;
            background-color: rgba(255, 255, 255, 0.2);
            cursor: default;
          }
        }
        .c-conversation-menu__list-unread {
          width: 16px;
          height: 16px;
          background-color: #6680ff;
          color: #fff;
          position: absolute;
          right: 4px;
          top: -2px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
        }
      }
      .c-conversation-menu__tabs-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
      }
      .connection-status {
        border-radius: 50%;
        height: 12px;
        width: 12px;
        transform: scale(1);
        animation: pulse 2s infinite;
        background: #e0102b;
        box-shadow: 0 0 0 0 rgba(224, 16, 43, 1);
      }

      .c-conversation-menu__icon-add {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        cursor: pointer;
        background-color: rgba(243, 244, 244, 0.2);
        transition: transform 0.3s ease;
        &.active {
          transform: rotate(45deg);
        }
      }
    }
    .c-conversation-menu__body {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 100%;
      .c-conversation-menu__conversations {
        .c-conversation-menu__swipeout-action {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;
          cursor: pointer;
          .c-conversation-menu__swipeout-inner {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            .c-conversation-menu__icon {
              fill: #fff;
            }
          }
        }
        .c-conversation-menu__swipeout-action--reached {
          -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
          -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
        }
        .c-conversation-menu__swipeout-action--resolve {
          background-color: #28b53b;
          .c-conversation-menu__swipeout-inner {
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            margin-right: 42px;
          }
        }
        .c-conversation-menu__swipeout-action--resolve.c-conversation-menu__swipeout-action--reached {
          -webkit-animation-name: c-conversation-menu-swipe-resolve-reached;
          animation-name: c-conversation-menu-swipe-resolve-reached;
        }
        .c-conversation-menu__swipeout-action--remove {
          background-color: #ee334b;
          .c-conversation-menu__swipeout-inner {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            margin-left: 42px;
          }
        }
        .c-conversation-menu__swipeout-action--remove.c-conversation-menu__swipeout-action--reached {
          -webkit-animation-name: c-conversation-menu-swipe-remove-reached;
          animation-name: c-conversation-menu-swipe-remove-reached;
        }
        .swipeout-left {
          width: 100%;
        }
        .swipeout-right {
          width: 100%;
        }
        .swipeout-content {
          z-index: 2;
          .c-conversation-menu__conversation {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
          }
        }
        .c-conversation-menu__infinite-wrapper {
          overflow: hidden;
          height: 80px;
          .c-conversation-menu__infinite-loading {
            margin-top: 20px;
          }
        }
        .c-conversation-menu__loading {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
        }
      }
      .c-conversation-menu__information {
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        .c-conversation-menu__empty-spot {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          width: 280px;
          :deep(.c-base-empty-spot__title) {
            color: #fff;
          }

          :deep(.c-base-empty-spot__description) {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
      .c-conversation-menu__conversations--text {
        color: #fff;
        font-weight: 700;
        margin: 20px;
        display: flex;
      }
    }
    .c-conversation-menu__divider {
      z-index: 5;
      position: absolute;
      right: -3px;
      width: 7px;
      height: 100%;
      .c-conversation-menu__divider-handle {
        width: 1px;
        height: 100%;
        margin: 0 auto;
        background-color: #e3e8ef;
        -webkit-transition:
          background-color 0.15s linear,
          width 0.15s linear;
        transition:
          background-color 0.15s linear,
          width 0.15s linear;
      }
      &:active {
        .c-conversation-menu__divider-handle {
          width: 3px;
          background-color: #0093ee;
        }
      }
      &:hover {
        .c-conversation-menu__divider-handle {
          width: 3px;
          background-color: #0093ee;
        }
      }
    }
    .c-conversation-menu__divider--snapped {
      .c-conversation-menu__divider-handle {
        width: 7px !important;
        -webkit-transition: width 0.2s ease;
        transition: width 0.2s ease;
      }
    }
  }
}
.c-conversation-menu--with-items {
  .c-conversation-menu__inner {
    .c-conversation-menu__divider {
      z-index: 3;
    }
  }
}
.c-conversation-menu--narrow {
  .c-conversation-menu__inner {
    .c-conversation-menu__tabs {
      .c-conversation-tabs__item--filters {
        .c-conversation-tabs__name {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 75em) {
  .c-conversation-menu--default-width {
    width: 300px !important;
    .c-conversation-menu__inner {
      .c-conversation-menu__tabs {
        .c-conversation-tabs__item--filters {
          .c-conversation-tabs__name {
            display: none;
          }
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(224, 16, 43, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(224, 16, 43, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(224, 16, 43, 0);
  }
}
</style>
