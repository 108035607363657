import posthog from "posthog-js";
import * as amplitude from "@amplitude/analytics-browser";

class AnalyticsProduct {
  constructor() {
    this.__client = null;
    this.initialized = false;
  }

  init() {
    /**
     * Initialize Posthog SDK
     * https://posthog.com/
     */
    // if (process.env.NODE_ENV === "production") {
    //   this.__client = posthog.init(
    //     "phc_5eakCa0kcOKAmT9LQio7Ijy7pPuikRPOlrd1PzC0NHa",
    //     {
    //       api_host: "https://app.posthog.com",
    //       autocapture: false,
    //       capture_pageview: false,
    //       capture_pageleave: false,
    //       disable_session_recording: true,
    //       enable_recording_console_log: false,
    //     },
    //   );

    //   /**
    //    * Initialize Amplitude tracker
    //    * https://posthog.com/
    //    */
    //   amplitude.init("a1e215b57777bbb055727bf5096c3144");

    //   /**
    //    * Declare class initialized
    //    */
    //   this.initialized = true;
    // }
  }

  track(eventName, properties = {}) {
    if (this.initialized) {
      amplitude.track(eventName, properties);
    }
  }

  capture(eventName, properties = {}) {
    if (this.initialized) {
      this.__client.capture(eventName, properties);
    }
  }

  identify({ operatorID, operatorName, operatorEmail, organizationID = null }) {
    if (this.initialized) {
      /**
       * Identify at posthog
       */
      this.__client.identify(operatorID, {
        name: operatorName,
        email: operatorEmail,
      });

      /**
       * Identify at amplitude
       */
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set("operatorID", operatorID);
      identifyEvent.set("name", operatorName);
      identifyEvent.set("email", operatorEmail);
      amplitude.identify(identifyEvent);

      /**
       * Only when organization was identified
       */
      if (typeof organizationID === "string") {
        this.__client.people.set({ organizationID: organizationID });
        amplitude.setGroup("organizationID", organizationID);
      }
    }
  }

  sessionRecord() {
    if (this.initialized) {
      this.__client.startSessionRecording();
    }
  }
}

export default new AnalyticsProduct();
