<template>
  <div
    :data-direction="direction"
    :data-align="align"
    :class="[
      'c-base-dropdown-reinvent',
      {
        'c-base-dropdown--displayed': displayed,
      },
    ]"
  >
    <BaseIcon
      name="PlusCircle"
      size="25px"
      color="#fff"
      cursor="pointer"
      class="c-base-dropdown--icon"
      @click="onButtonClick"
    />

    <ul
      v-show="displayed"
      :class="['c-base-dropdown__menu', 'c-base-dropdown__menu--' + align]"
      :style="{
        'z-index': menuIndex,
      }"
    >
      <BaseDropdownItem
        v-for="item in items"
        :key="item.id"
        v-on:click="onItemClick"
        v-on:FileChange="onFileChange"
        v-on:AudioFile="onAudioFile"
        :can-show="item.condition"
        :disabled="item.disabled"
        :icon="item.icon"
        :id="item.id"
        :file="item.file"
        :label="item.label"
        :record="item.record"
        :upgrade="item.upgrade"
      ></BaseDropdownItem>
    </ul>
  </div>
</template>

<script>
import BaseDropdownItem from "@/components/Reinvent/Base/BaseDropdownItem.vue";

export default {
  components: { BaseDropdownItem },
  props: {
    align: {
      type: String,
      default: "right",
      validator(t) {
        return ["left", "right"].indexOf(t) !== -1;
      },
    },
    direction: {
      type: String,
      default: "top",
      validator(t) {
        return ["bottom", "top"].indexOf(t) !== -1;
      },
    },
    items: {
      type: Array,
      required: !0,
    },
    menuIndex: {
      type: [String, Number],
      default: "auto",
    },
    tooltip: {
      type: Object,
      default: null,
    },
  },
  emits: ["fileChange", "audioFile", "itemClick"],
  data() {
    return {
      displayed: !1,
    };
  },
  computed: {
    hotkeys() {
      return {
        esc: this.onClose,
      };
    },
  },
  methods: {
    onButtonClick() {
      this.displayed = !this.displayed;
    },
    onClose() {
      this.displayed && (this.displayed = !1);
    },
    onFileChange(e) {
      this.$emit("fileChange", e);
    },
    onAudioFile(e, t) {
      this.$emit("audioFile", e, t);
    },
    onItemClick(e) {
      this.displayed = !1;
      this.$emit("itemClick", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-base-dropdown-reinvent {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .c-base-dropdown--icon {
    transform: rotate(0);
    transition: transform 0.2s ease;
  }

  .c-base-dropdown__button {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    display: inline-block;
  }
  .c-base-dropdown__menu {
    position: absolute;
    margin-left: 0px;
    bottom: 13px;
    padding: 5px 0;
    border-radius: 1px;
    background-color: #757576;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    z-index: 10;
    list-style: none;
    -webkit-animation-name: translateBottomToTop;
    animation-name: translateBottomToTop;
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border-radius: 20px;
    &:after {
      position: absolute;
      top: 100%;
      width: 0;
      height: 0;
      border: solid transparent;
      content: " ";
      pointer-events: none;
      border-width: 8px;
      border-color: none;
      border-top-color: #757576;
    }
    &:before {
      position: absolute;
      top: 100%;
      width: 0;
      height: 0;
      border: solid transparent;
      content: " ";
      pointer-events: none;
      border-width: 9px;
      border-color: none;
      border-top-color: #757576;
    }
  }
  .c-base-dropdown__menu--left {
    left: -13px;
    &:after {
      left: 17px;
      margin-right: -7px;
    }
    &:before {
      left: 15px;
      margin-right: -9px;
    }
  }
  .c-base-dropdown__menu--right {
    right: -13px;
    &:after {
      right: 17px;
      margin-left: -7px;
    }
    &:before {
      right: 15px;
      margin-left: -9px;
    }
  }
  &.c-base-dropdown--displayed {
    .c-base-dropdown--icon {
      transform: rotate(135deg);
    }
  }
}
</style>
