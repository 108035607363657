<template>
  <div
    :class="[
      'c-conversation-box-content-message-bubble',
      'c-conversation-box-content-message-bubble--' + from,
      'c-conversation-box-content-message-bubble--' + type,
      {
        'c-conversation-box-content-message-bubble--has-content': content,
        'c-conversation-box-content-message-bubble--has-error': error,
        'c-conversation-box-content-message-bubble--no-shadow': disableShadow,
        'c-conversation-box-content-message-bubble--expand':
          shouldDisplayOriginal,
        'c-conversation-box-content-message-bubble--file-caption':
          content.caption,
      },
    ]"
    ref="root"
  >
    <div
      :class="[
        'c-conversation-box-content-message-bubble__wrapper',
        {
          'c-conversation-box-content-message-bubble__wrapper--blurred':
            null !== restriction,
          'c-conversation-box-content-message-bubble__wrapper--expand':
            shouldDisplayOriginal,
        },
      ]"
    >
      <!-- message-type-note -->
      <span
        v-if="'note' === type"
        class="c-conversation-box-content-message-bubble__note-header"
      >
        {{
          this.$filters.format.parseFirstName(nickname) +
          " " +
          this.$t("INBOX.FLOW.LEFT_PRIVATE_NOTE")
        }}
      </span>

      <!-- message-type-text | email | shouldDisplayOriginal -->
      <span
        v-if="'text' === type && shouldDisplayOriginal"
        :class="[
          'c-conversation-box-content-message-bubble__content',
          'c-conversation-box-content-message-bubble__content--email',
        ]"
      >
        <BaseSpinner
          v-if="originalEmail.loading"
          border-width="2px"
          color="#2c405a"
          size="20px"
          speed="2s"
        ></BaseSpinner>
        <div v-else>
          <a
            class="c-conversation-box-content-message-bubble__expand"
            v-on:click="
              (...e) =>
                onExpandOriginalEmailFrame && onExpandOriginalEmailFrame(...e)
            "
          >
            <BaseIcon
              class="c-conversation-box-content-message-bubble__icon"
              name="open_in_full"
              size="17px"
              color="#ffffff"
            ></BaseIcon>
          </a>
          <iframe
            class="c-conversation-box-content-message-bubble__email"
            v-on:load="
              (...e) =>
                onLoadOriginalEmailFrame && onLoadOriginalEmailFrame(...e)
            "
            v-on:keydown="
              (...e) =>
                onOriginalEmailFrameKeydown && onOriginalEmailFrameKeydown(...e)
            "
            :srcdoc="originalEmail.content || original.content"
            ref="originalEmailFrame"
            type="text/html"
            frameborder="0"
            sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          ></iframe>
        </div>
      </span>

      <!-- message-type-text | message-type-note -->
      <span
        v-else-if="'text' === type || 'note' === type"
        :innerHTML="contentOrShuffle"
        :class="[
          'c-conversation-box-content-message-bubble__content',
          {
            'c-conversation-box-content-message-bubble__content--rtl':
              'rtl' === languageDirection,
          },
        ]"
      ></span>

      <!-- message-type-file -->
      <span
        v-if="'file' === type && 'image' !== previewType"
        class="c-conversation-box-content-message-bubble__content"
      >
        {{ content.name }}
      </span>

      <!-- message-type-event -->
      <span
        v-if="'event' === type"
        class="c-conversation-box-content-message-bubble__content"
        :data-event="content.eventType"
      >
        <span class="c-conversation-box-content-message-bubble__icon"></span>

        <div v-if="'session:set:closed' === content.eventType">
          <span>{{ this.$t("TALQUI.INBOX.FLOW.EVENT_STATE_SET_CLOSED") }}</span>
        </div>

        <div v-else-if="'session:set:routing' === content.eventType">
          <span>
            {{ this.$t("TALQUI.INBOX.FLOW.EVENT_STATE_SET_ROUTING") }}
          </span>
        </div>

        <div v-else-if="'session:tag:added' === content.eventType">
          <span>
            {{ this.$t("TALQUI.INBOX.FLOW.EVENT_STATE_TAG_ADDED") }}
            <span class="c-conversation-box-content-message-tag__item">
              {{ content.eventTag }}
            </span>
          </span>
        </div>

        <div v-else-if="'session:tag:removed' === content.eventType">
          <span>
            {{ this.$t("TALQUI.INBOX.FLOW.EVENT_STATE_TAG_REMOVED") }}
            <span class="c-conversation-box-content-message-tag__item">
              {{ content.eventTag }}
            </span>
          </span>
        </div>

        <div v-else-if="'session:call:ended' === content.eventType">
          <span>
            {{ this.$t("TALQUI.INBOX.FLOW.EVENT_STATE_CALL_ATTEMPT") }}
          </span>
        </div>

        <div v-else-if="'session:set:change_type' === content.eventType">
          <span>
            {{ this.$t("TALQUI.INBOX.FLOW.EVENT_STATE_CHANGE_TYPE") }}
          </span>
        </div>

        <div v-else-if="'user:blocked' === content.eventType">
          <span>{{ this.$t("INBOX.FLOW.EVENT_USER_BLOCKED") }}</span>
        </div>
        <div v-else-if="'reminder:scheduled' === content.eventType">
          <span>{{ this.$t("INBOX.FLOW.EVENT_REMINDER_SCHEDULED") }}</span>
        </div>
        <div v-else-if="'thread:started' === content.eventType">
          <span>{{ this.$t("INBOX.FLOW.EVENT_THREAD_STARTED") }}</span>
        </div>
        <div v-else-if="'thread:ended' === content.eventType">
          <span>{{ this.$t("INBOX.FLOW.EVENT_THREAD_ENDED") }}</span>
        </div>
        <div v-else-if="'participant:added' === content.eventType">
          <span>{{ this.$t("participant:added") }}</span>
        </div>
        <div v-else-if="'participant:removed' === content.eventType">
          <span>{{ this.$t("INBOX.FLOW.EVENT_PARTICIPANT_REMOVED") }}</span>
        </div>
        <div v-else-if="'call:started' === content.eventType">
          <span>{{ this.$t("INBOX.FLOW.EVENT_CALL_STARTED") }}</span>
        </div>
        <div v-else-if="'call:ended' === content.eventType">
          <span>{{ this.$t("INBOX.FLOW.EVENT_CALL_ENDED") }}</span>
        </div>
        <span v-else>{{ this.$t("INBOX.FLOW.EVENT_DEFAULT") }}</span>
        <span
          v-if="content.text"
          class="c-conversation-box-content-message-bubble__text u-ellipsis u-regular"
        >
          {{ content.text }}
        </span>
      </span>

      <!-- message-type-compose | message-type-composepreview -->
      <span
        v-if="'compose' === type || 'compose-preview' === type"
        :class="[
          'c-conversation-box-content-message-bubble__content',
          {
            'c-conversation-box-content-message-bubble__content--compose-is-cut':
              content.length > 28,
          },
        ]"
      >
        <span
          v-if="content && content.length > 0"
          :innerHTML="
            'compose-preview' === type
              ? this.$filters.format.parseText(
                  this.$filters.format.cutText(content, 28, '', !0),
                )
              : this.$filters.format.parseText(content)
          "
        ></span>
        <span
          v-if="content && content.length > 0"
          class="c-conversation-box-content-message-bubble__compose-cursor"
        ></span>
        <span
          v-if="!(content && 0 !== content.length)"
          class="c-conversation-box-content-message-bubble__sub"
        >
          <span class="c-conversation-box-content-message-bubble__dot"></span>
          <span class="c-conversation-box-content-message-bubble__dot"></span>
          <span class="c-conversation-box-content-message-bubble__dot"></span>
        </span>
      </span>

      <!-- message-type-field -->
      <span
        v-if="'field' === type || 'picker' === type"
        class="c-conversation-box-content-message-bubble__content"
        :innerHTML="this.$filters.format.parseText(content.text)"
      ></span>

      <!-- message-has-preview -->
      <span
        v-if="preview.length > 0 && !shouldDisplayOriginal"
        class="c-conversation-box-content-message-bubble__preview"
      >
        <a
          v-if="isEmbedVideo"
          class="c-conversation-box-content-message-bubble__embed"
          v-on:click="(e) => onPreviewEmbed(preview[0].preview.embed)"
          :style="embedStyle"
        ></a>
        <BaseLink
          v-if="isEmbedImage"
          class="c-conversation-box-content-message-bubble__image"
          :href="preview[0].url"
          :style="embedStyle"
        ></BaseLink>
        <BaseLink
          v-if="isEmbedVideo"
          class="c-conversation-box-content-message-bubble__preview-link u-bold"
          :href="preview[0].url"
        >
          {{ preview[0].title || preview[0].website || preview[0].url }}
        </BaseLink>
      </span>

      <!-- message-type-location -->
      <span
        v-if="'location' === type && linkLocation !== null"
        class="c-conversation-box-content-message-bubble__preview"
      >
        <!-- <a
            v-if="isEmbedVideo"
            class="c-conversation-box-content-message-bubble__embed"
            v-on:click="(e) => onPreviewEmbed(preview[0].preview.embed)"
            :style="embedStyle"
          ></a> -->
        <!-- <BaseLink
            class="c-conversation-box-content-message-bubble__image"
            :href="'https://maps.google.com/?q=-22.1107063,-47.081256'"
            :style="{}"
          ></BaseLink> -->
        <BaseLink
          class="c-conversation-box-content-message-bubble__preview-link u-bold"
          style="margin-top: 2px"
          :href="linkLocation"
        >
          Localização
        </BaseLink>
      </span>

      <!-- message-type-image -->
      <span
        v-if="'image' === previewType"
        class="c-conversation-box-content-message-bubble__image"
        v-on:click="onPreviewFile(content.address, content.mimeType)"
        :style="{
          'background-image': 'url(' + previewUrl + ')',
        }"
      ></span>

      <!-- message-type-download -->
      <span
        v-if="'download' === previewType"
        class="c-conversation-box-content-message-bubble__link u-bold"
        v-on:click="(e) => onDownloadFile(content.address)"
        data-file="download"
      >
        {{ this.$t("INBOX.FLOW.DOWNLOAD_FILE") }}
      </span>

      <!-- message-type-file -->
      <span
        v-else-if="'file' === type && 'image' !== previewType"
        class="c-conversation-box-content-message-bubble__link u-bold"
        v-on:click="onPreviewFile(content.address, content.mimeType)"
        :data-file="previewType"
      >
        <span v-if="'video' === previewType">
          {{ this.$t("INBOX.FLOW.PREVIEW_VIDEO") }}
        </span>
      </span>

      <!-- message-type-field -->
      <span
        v-if="'field' === type"
        :class="[
          'c-conversation-box-content-message-bubble__field',
          {
            'c-conversation-box-content-message-bubble__field--empty':
              !content.value,
            'u-bold': content.value,
            'u-select': content.value,
          },
        ]"
      >
        {{ content.value || content.explain }}
      </span>

      <!-- message-type-animation -->
      <span
        v-if="'animation' === type"
        class="c-conversation-box-content-message-bubble__image"
        :style="{
          backgroundImage: 'url(' + animationUrl + ')',
        }"
      >
        <span
          class="c-conversation-box-content-message-bubble__source"
          :data-source="this.$filters.format.extractDomain(content.address)"
        ></span>
      </span>

      <!-- message-type-picker -->
      <div
        v-if="'picker' === type"
        :class="[
          'c-conversation-box-content-message-bubble__picker',
          {
            'c-conversation-box-content-message-bubble__picker--has-selected':
              hasPickerSelected,
            'u-bold': content.value,
          },
        ]"
        :data-choices-count="content.choices.length"
      >
        <div
          v-for="choice in content.choices"
          :key="choice.id"
          :class="[
            'c-conversation-box-content-message-bubble__choice',
            {
              'c-conversation-box-content-message-bubble__choice--not-selected':
                !choice.selected,
              'u-semibold': !choice.selected && hasPickerSelected,
              'u-bold': choice.selected,
            },
          ]"
        >
          {{ choice.label }}
        </div>
      </div>

      <!-- message-type-audio -->
      <div
        v-if="'audio' === type"
        class="c-conversation-box-content-message-bubble__audio"
        v-crispAudio="audio.duration"
      >
        <span class="c-conversation-box-content-message-bubble__wrapped">
          <span
            class="c-conversation-box-content-message-bubble__icon js-audio-action"
          ></span>
          <span class="c-conversation-box-content-message-bubble__row"></span>
          <span
            class="c-conversation-box-content-message-bubble__duration js-audio-duration"
          >
            {{ this.$filters.date.formatTime(audio.duration) }}
          </span>
        </span>
        <span
          class="c-conversation-box-content-message-bubble__progress js-audio-progress"
          :style="{
            width: '0%',
          }"
        ></span>
        <span
          class="c-conversation-box-content-message-bubble__jump js-audio-jump"
          :style="{
            width: '0%',
          }"
        ></span>
        <audio
          class="c-conversation-box-content-message-bubble__media js-audio-media"
          preload="metadata"
          ref="voiceAudio"
        >
          <source
            class="js-audio-source"
            :src="content.address"
            :type="content.mimeType"
          />
        </audio>
      </div>

      <BaseFlag
        v-if="translationLocale"
        class="c-conversation-box-content-message-bubble__flag"
        :locale="translationLocale"
        height="14px"
        width="14px"
        circular=""
      ></BaseFlag>

      <div
        v-if="content.caption"
        class="c-conversation-box-content-message-bubble__caption"
      >
        <span>{{ content.caption }}</span>
      </div>

      <span class="c-conversation-box-content-message-bubble__time">
        {{ formatTimestamp(timestamp) }}
      </span>
    </div>
  </div>
</template>

<script>
import escape from "lodash.escape";
import startsWith from "lodash.startswith";

import * as n from "@/enviroment";
// import * as r from "@/components/FilePreview.vue";
import * as s from "@/components/OriginalViewer.vue";
import * as c from "@/classes/linkHelper";

import FilePreview from "@/components/FilePreview.vue";

const M = 600;
export default {
  props: {
    content: {
      type: [String, Object],
      required: !0,
    },
    fingerprint: {
      type: [String, Number],
      default: "",
    },
    from: {
      type: String,
      default: "user",
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    disableShadow: {
      type: Boolean,
      default: false,
    },
    disableDownload: {
      type: Boolean,
      default: false,
    },
    messageIndex: {
      type: Number,
      default: 0,
    },
    error: {
      type: Boolean,
      default: false,
    },
    languageDirection: {
      type: String,
      default: "ltr",
    },
    nickname: {
      type: String,
      default: null,
    },
    origin: {
      type: String,
      default: "chat",
    },
    original: {
      type: Object,
      default: () => ({}),
    },
    edited: {
      type: Boolean,
      default: false,
    },
    translated: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Array,
      default: () => [],
    },
    sessionId: {
      type: String,
      required: !0,
    },
    timestamp: {
      type: Number,
      default: null,
    },
    translationLocale: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    websiteId: {
      type: String,
      required: !0,
    },
  },
  data() {
    return {
      audio: {
        state: "none",
        duration: 0,
      },
      originalEmail: {
        content: "",
        loading: false,
      },
      showTooltip: false,
    };
  },
  computed: {
    animationUrl() {
      let e = encodeURIComponent(this.content.address);
      return this.$CONFIG.CRISP_URL_IMAGE + "/process/original/?url=" + e;
    },
    canBePreviewed() {
      return this.isImage || this.isVideo;
    },
    previewType() {
      return "file" !== this.type
        ? null
        : -1 !==
            [
              "image/jpg",
              "image/jpeg",
              "image/png",
              "image/gif",
              "image/webp",
            ].indexOf(this.content.mimeType)
          ? "image"
          : -1 !== ["video/mp4", "video/webm"].indexOf(this.content.mimeType)
            ? "video"
            : "download";
    },
    embedStyle() {
      let e = this.$filters.format.encodeUri(this.preview[0].preview.image);
      if (e)
        return startsWith(e, n.default.CRISP_URL_IMAGE + "/")
          ? `background-image: url('${e}') !important;`
          : `background-image: url('${this.$CONFIG.CRISP_URL_IMAGE}/process/resize/?url=${e}&width=${M}&height=${M}') !important;`;
    },
    hasPickerSelected() {
      for (let e in this.content.choices)
        if (!0 === this.content.choices[e].selected) return !0;
      return false;
    },
    isEmbedVideo() {
      return (
        !(!this.preview || !this.preview[0]) &&
        this.preview[0].preview &&
        this.preview[0].preview.embed &&
        this.preview[0].preview.image
      );
    },
    isEmbedImage() {
      return (
        !(!this.preview || !this.preview[0]) &&
        this.preview[0].preview &&
        !this.preview[0].preview.embed &&
        this.preview[0].preview.image
      );
    },
    canShowMessage() {
      return true;
    },
    contentOrShuffle() {
      return this.canShowMessage
        ? this.$filters.format.parseText(this.content)
        : escape(
            this.content
              .split("")
              .sort(() => 0.5 - Math.random())
              .join(""),
          );
    },
    previewUrl() {
      return "image/gif" === this.content.mimeType
        ? this.animationUrl
        : this.content.address;
    },
    linkLocation() {
      if (this.type === "location") {
        let __coordinates = this.content.split(":");
        return `https://maps.google.com/?q=${__coordinates[1]},${__coordinates[0]}`;
      }

      return null;
    },
    shouldDisplayOriginal() {
      return (
        "email" === this.origin &&
        "text" === this.type &&
        !(!this.original || !this.original.original_id) &&
        !(this.original.loaded && !this.original.content) &&
        !(this.originalEmail.loaded && !this.originalEmail.content) &&
        !this.translationLocale &&
        -1 === this.content.indexOf("\n>") &&
        !(this.content.split("\n").length < 20)
      );
    },
    restriction() {
      // return "compose" === this.type || "compose-preview" === this.type
      //   ? null
      //   : this.canShowMessage
      //   ? "audio" !== this.type ||
      //     this.$pluginExists("urn:crisp.im:message-audio:0")
      //     ? "note" !== this.type ||
      //       this.$pluginExists("urn:crisp.im:message-notes:0")
      //       ? null
      //       : "notes"
      //     : "audio"
      //   : "history";
      return null;
    },
    upgradeTitle() {
      switch (this.restriction) {
        case "audio":
          return this.$t("RESTRICTS.AUDIO.TITLE");
        case "notes":
          return this.$t("RESTRICTS.NOTES.TITLE");
        default:
          return this.$t("RESTRICTS.HISTORY.TITLE");
      }
    },
    upgradeLabel() {
      switch (this.restriction) {
        case "audio":
          return this.$t("RESTRICTS.AUDIO.LABEL");
        case "notes":
          return this.$t("RESTRICTS.NOTES.LABEL");
        default:
          return this.$t("RESTRICTS.HISTORY.LABEL");
      }
    },
    labelSentMessage() {
      if ("operator" === this.from) {
        if (typeof this.nickname === "string") {
          return `Enviado por ${this.$filters.format.parseFirstName(
            this.nickname,
          )}`;
        }

        return "Enviado por Chatbot";
      }

      return "Recebido do Contato";
    },
  },
  mounted() {
    if (this.$refs.voiceAudio) {
      this.$refs.voiceAudio.addEventListener(
        "loadedmetadata",
        this.audioLoaded,
        false,
      );
    }
  },
  methods: {
    formatTimestamp(timestamp) {
      return new Intl.DateTimeFormat("pt-BR", {
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(timestamp));
    },
    audioLoaded() {
      this.audio.duration = this.$refs.voiceAudio.duration;
    },
    onDownloadFile(e) {
      c.default.openExternal(e);
    },
    onPreviewFile(address = "", mimeType = null) {
      let i = this.$store.getters["sessionsTalqui/getSessionReference"](
        this.websiteId,
        this.sessionId,
      );

      FilePreview.show(
        this.websiteId,
        this.sessionId,
        i.messages || [],
        this.fingerprint,
        "file",
        address,
        mimeType,
        this.disableDownload,
      );
    },
    onPreviewEmbed(e) {
      FilePreview.show(
        this.websiteId,
        this.sessionId,
        [],
        this.fingerprint,
        "embed",
        e,
      );
    },
    onPreviewOriginal(e) {
      s.default.loadAndShow(this.websiteId, this.sessionId, e);
    },
    onExpandOriginalEmailFrame() {
      this.original &&
        this.original.original_id &&
        this.onPreviewOriginal(this.original.original_id);
    },
    onLoadOriginalEmailFrame() {
      let e = this.$refs.originalEmailFrame.contentWindow.document,
        t = e.body.getElementsByTagName("a") || [];
      for (let i = 0; i < t.length; i++) t[i].target = "_blank";
      e.addEventListener("keydown", this.onOriginalEmailFrameKeydown);
    },
    onOriginalEmailFrameKeydown(e) {
      if ("f" === e.key && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(), e.stopPropagation();
        let t = new KeyboardEvent("keydown", e);
        document.dispatchEvent(t);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-conversation-box-content-message-bubble {
  position: relative;
  max-width: 640px;
  .c-conversation-box-content-message-bubble__time {
    font-size: 12px;
    display: block;
    margin-top: 12px;
  }
  .c-conversation-box-content-message-bubble__wrapper {
    position: relative;
    padding: 12px 20px;
    max-width: 640px;
    border-radius: 12px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 16px;
    &:hover {
      .c-conversation-box-content-message-bubble__content--email {
        .c-conversation-box-content-message-bubble__expand {
          visibility: visible;
        }
      }
    }
  }
  .c-conversation-box-content-message-bubble__wrapper--expand {
    width: 100%;
    max-width: 1000px;
  }
  .c-conversation-box-content-message-bubble__caption {
    margin-top: 4px;
  }
  .c-conversation-box-content-message-bubble__content {
    display: block;
    overflow: hidden;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    .o-link {
      word-break: break-all;
    }
    .o-markdown.o-markdown-blockquote {
      border-color: rgba(0, 0, 0, 0.15);
    }
    .o-markdown.o-markdown-table {
      td {
        border-color: rgba(0, 0, 0, 0.15);
      }
      th {
        border-color: rgba(0, 0, 0, 0.15);
      }
      thead {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }
    .o-markdown.o-markdown-code {
      background-color: #eff5fe;
    }
    .o-markdown.o-markdown-line {
      background-color: rgba(0, 0, 0, 0.15);
    }
    .o-markdown.o-markdown-link.o-markdown-link-text {
      text-decoration: underline;
    }
    .o-markdown.o-markdown-link.o-markdown-link-image {
      .o-markdown-image {
        -webkit-transition: -webkit-filter 0.15s linear;
        transition: -webkit-filter 0.15s linear;
        transition: filter 0.15s linear;
        transition:
          filter 0.15s linear,
          -webkit-filter 0.15s linear;
        &:hover {
          -webkit-filter: brightness(95%);
          filter: brightness(95%);
          cursor: pointer;
        }
      }
    }
    .o-smiley[data-size="large"] {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .c-conversation-box-content-message-bubble__content--rtl {
    direction: rtl;
  }
  .c-conversation-box-content-message-bubble__content--email {
    min-height: 600px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    .c-conversation-box-content-message-bubble__expand {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 5px;
      line-height: 0;
      cursor: pointer;
      visibility: hidden;
      display: block;
      position: absolute;
      top: 6px;
      right: 8px;
      z-index: 1;
      border-radius: 3px;
      -webkit-transition: background-color 0.1s linear;
      transition: background-color 0.1s linear;
      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.65);
      }
    }
    .c-conversation-box-content-message-bubble__email {
      min-height: 600px;
      width: 100%;
      border-radius: 5px;
      -webkit-animation: fadeIn;
      animation: fadeIn;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }
  .c-conversation-box-content-message-bubble__preview {
    .c-conversation-box-content-message-bubble__embed {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 9px -3px 2px;
      margin-right: auto;
      margin-left: auto;
      min-width: 180px;
      max-width: 600px;
      width: 100%;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
      height: 100px;
      border-radius: 14px;
      &:after {
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.15);
        content: "";
      }
      &:hover {
        .c-conversation-box-content-message-bubble__play {
          -webkit-transform: scale(1.15);
          transform: scale(1.15);
        }
      }
      &:active {
        .c-conversation-box-content-message-bubble__play {
          -webkit-transform: scale(0.95);
          transform: scale(0.95);
        }
      }
      .c-conversation-box-content-message-bubble__play {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        margin-top: -19px;
        margin-left: -19px;
        width: 38px;
        height: 38px;
        -webkit-transition: -webkit-transform 0.15s linear;
        transition: -webkit-transform 0.15s linear;
        transition: transform 0.15s linear;
        transition:
          transform 0.15s linear,
          -webkit-transform 0.15s linear;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48Y2lyY2xlIGN4PSIxOSIgY3k9IjE5IiByPSIxOSIgZmlsbD0iIzAwMCIgZmlsbC1vcGFjaXR5PSIuMTQ2IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yOCAxOWwtMTQgOVYxMHoiLz48L2c+PC9zdmc+");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        display: inline-block;
      }
    }
    .c-conversation-box-content-message-bubble__image {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 9px -3px 2px;
      margin-right: auto;
      margin-left: auto;
      min-width: 180px;
      max-width: 600px;
      width: 100%;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
      background-color: rgba(0, 0, 0, 0.075);
      height: 80px;
      border-radius: 10px;
      -webkit-transition: -webkit-filter 0.15s linear;
      transition: -webkit-filter 0.15s linear;
      transition: filter 0.15s linear;
      transition:
        filter 0.15s linear,
        -webkit-filter 0.15s linear;
      &:hover {
        -webkit-filter: brightness(95%);
        filter: brightness(95%);
      }
    }
    .c-conversation-box-content-message-bubble__preview-link {
      display: block;
      overflow: hidden;
      margin: 9px -2px 2px;
      padding: 0 16px;
      height: 31px;
      border-radius: 31px;
      text-align: center;
      word-break: break-all;
      font-size: 13px;
      line-height: 31px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .c-conversation-box-content-message-bubble__flag {
    position: absolute;
    bottom: -1px;
    border-radius: 100%;
    -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
    -webkit-animation: bounceIn;
    animation: bounceIn;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .c-conversation-box-content-message-bubble__tooltip {
    .c-conversation-box-content-message-bubble__sub {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .c-conversation-box-content-message-bubble__tags {
      padding-left: 6px;
      text-transform: lowercase;
      opacity: 0.75;
    }
    .c-conversation-box-content-message-bubble__actions {
      margin-left: 4px;
      &:before {
        padding: 0 1px;
        content: "–";
        opacity: 0.6;
      }
      .c-conversation-box-content-message-bubble__action {
        margin-left: 4px;
        text-decoration: underline;
        text-transform: lowercase;
        font-weight: 700;
        font-style: normal;
        cursor: pointer;
      }
    }
    .c-conversation-box-content-message-bubble__icon {
      margin-right: 4px;
      margin-bottom: 1px;
      fill: #fff;
    }
  }
  &:hover {
    .c-conversation-box-content-message-bubble__tooltip {
      display: block;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    .c-conversation-box-content-message-bubble__tooltip--first {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }
  &:active {
    .c-conversation-box-content-message-bubble__wrapper {
      &:not(.c-conversation-box-content-message-bubble__wrapper--blurred) {
        opacity: 0.9;
      }
    }
  }
}

.c-conversation-box-content-message-bubble--user {
  .c-conversation-box-content-message-bubble__time {
    text-align: left;
  }
  .c-conversation-box-content-message-bubble__wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: rgba(117, 117, 118, 0.2);
    color: #fff;
  }
  .c-conversation-box-content-message-bubble__field {
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNSI+PHBhdGggZmlsbD0iIzE5MTkxOSIgZD0iTTYuMjQgMTEuMTdsNS42LTUuNmMuMTItLjEuMTctLjI1LjE3LS40cy0uMDUtLjMtLjE3LS40MWwtLjkzLS45M2MtLjEyLS4xMi0uMjUtLjE4LS40MS0uMThzLS4zLjA2LS40MS4xOEw1LjgzIDguMDggMy45MSA2LjE2Yy0uMTItLjEyLS4yNS0uMTctLjQxLS4xN3MtLjMuMDUtLjQxLjE3bC0uOTMuOTNjLS4xMi4xMi0uMTcuMjUtLjE3LjQxcy4wNS4zLjE3LjQxbDMuMjYgMy4yNmEuNTU0LjU1NCAwIDAwLjgyIDB6TTE0IDMuMTN2OC43NWMwIC43Mi0uMjYgMS4zNC0uNzcgMS44NXMtMS4xMy43Ny0xLjg2Ljc3SDIuNjNjLS43MyAwLTEuMzUtLjI2LTEuODYtLjc3QTIuNTMgMi41MyAwIDAxMCAxMS44N1YzLjEzYzAtLjczLjI2LTEuMzUuNzctMS44NkEyLjUzIDIuNTMgMCAwMTIuNjIuNWg4Ljc1Yy43MyAwIDEuMzUuMjYgMS44Ni43N1MxNCAyLjQgMTQgMy4xMnoiLz48L3N2Zz4=");
    }
    border: 2px solid rgba(0, 0, 0, 0.65);
    color: #000;
  }
  .c-conversation-box-content-message-bubble__picker--has-selected {
    .c-conversation-box-content-message-bubble__choice {
      &:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNSI+PHBhdGggZmlsbD0iIzE5MTkxOSIgZD0iTTYuMjQgMTEuMTdsNS42LTUuNmMuMTItLjEuMTctLjI1LjE3LS40cy0uMDUtLjMtLjE3LS40MWwtLjkzLS45M2MtLjEyLS4xMi0uMjUtLjE4LS40MS0uMThzLS4zLjA2LS40MS4xOEw1LjgzIDguMDggMy45MSA2LjE2Yy0uMTItLjEyLS4yNS0uMTctLjQxLS4xN3MtLjMuMDUtLjQxLjE3bC0uOTMuOTNjLS4xMi4xMi0uMTcuMjUtLjE3LjQxcy4wNS4zLjE3LjQxbDMuMjYgMy4yNmEuNTU0LjU1NCAwIDAwLjgyIDB6TTE0IDMuMTN2OC43NWMwIC43Mi0uMjYgMS4zNC0uNzcgMS44NXMtMS4xMy43Ny0xLjg2Ljc3SDIuNjNjLS43MyAwLTEuMzUtLjI2LTEuODYtLjc3QTIuNTMgMi41MyAwIDAxMCAxMS44N1YzLjEzYzAtLjczLjI2LTEuMzUuNzctMS44NkEyLjUzIDIuNTMgMCAwMTIuNjIuNWg4Ljc1Yy43MyAwIDEuMzUuMjYgMS44Ni43N1MxNCAyLjQgMTQgMy4xMnoiLz48L3N2Zz4=");
      }
    }
    .c-conversation-box-content-message-bubble__choice--not-selected {
      &:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE1IiB3aWR0aD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgZmlsbD0iIzE5MTkxOSIgZmlsbC1ydWxlPSJldmVub2RkIiBoZWlnaHQ9IjE1IiByeD0iMyIgd2lkdGg9IjE0Ii8+PC9zdmc+");
      }
      border-color: rgba(0, 0, 0, 0.3);
    }
  }
  .c-conversation-box-content-message-bubble__field--empty {
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE1IiB3aWR0aD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgZmlsbD0iIzE5MTkxOSIgZmlsbC1ydWxlPSJldmVub2RkIiBoZWlnaHQ9IjE1IiByeD0iMyIgd2lkdGg9IjE0Ii8+PC9zdmc+");
    }
    border-color: rgba(0, 0, 0, 0.3);
    color: #000;
  }
  .c-conversation-box-content-message-bubble__picker {
    .c-conversation-box-content-message-bubble__choice {
      border: 2px solid rgba(0, 0, 0, 0.65);
      color: #000;
    }
  }
  .c-conversation-box-content-message-bubble__link {
    background-color: #000;
    color: #fff;
  }
  .c-conversation-box-content-message-bubble__preview {
    .c-conversation-box-content-message-bubble__preview-link {
      background-color: #000;
      color: #fff;
    }
  }
  .c-conversation-box-content-message-bubble__link[data-file="video"] {
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOS41IDcuNWwtOSA3Vi41eiIvPjwvc3ZnPg==");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      display: inline-block;
    }
  }
  .c-conversation-box-content-message-bubble__link[data-file="download"] {
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOCAxNXYzSDZ2LTNINHYzYzAgMS4xLjkgMiAyIDJoMTJjMS4xIDAgMi0uOSAyLTJ2LTN6bS0xLTRsLTEuNDEtMS40MUwxMyAxMi4xN1Y0aC0ydjguMTdMOC40MSA5LjU5IDcgMTFsNSA1eiIvPjwvc3ZnPg==");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      display: inline-block;
    }
  }
  .c-conversation-box-content-message-bubble__compose-animation {
    .c-conversation-box-content-message-bubble__dot {
      background: #000;
    }
  }
  .c-conversation-box-content-message-bubble__compose-cursor {
    background: #000;
  }
  .c-conversation-box-content-message-bubble__flag {
    left: -2px;
  }
  &.c-conversation-box-content-message-bubble--has-error {
    .c-conversation-box-content-message-bubble__wrapper {
      background-color: rgba(0, 77, 240, 0.2);
    }
  }
}

.c-conversation-box-content-message-bubble--operator {
  .c-conversation-box-content-message-bubble__time {
    text-align: right;
  }
  .c-conversation-box-content-message-bubble__wrapper {
    background-color: #004df0;
    color: #fff;
  }
  .c-conversation-box-content-message-bubble__content {
    .o-link {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    .o-markdown {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    .o-markdown.o-markdown-blockquote {
      border-color: hsla(0, 0%, 100%, 0.25);
    }
    .o-markdown.o-markdown-table {
      td {
        border-color: hsla(0, 0%, 100%, 0.25);
      }
      th {
        border-color: hsla(0, 0%, 100%, 0.25);
      }
      thead {
        background-color: hsla(0, 0%, 100%, 0.15);
      }
    }
    .o-markdown.o-markdown-code {
      background-color: hsla(0, 0%, 100%, 0.25);
    }
    .o-markdown.o-markdown-line {
      background-color: hsla(0, 0%, 100%, 0.25);
    }
    .o-markdown.o-markdown-highlight {
      color: #000;
    }
  }
  .c-conversation-box-content-message-bubble__field {
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTYuMjQgMTEuMTdsNS42LTUuNmMuMTItLjEuMTctLjI1LjE3LS40cy0uMDUtLjMtLjE3LS40MWwtLjkzLS45M2MtLjEyLS4xMi0uMjUtLjE4LS40MS0uMThzLS4zLjA2LS40MS4xOEw1LjgzIDguMDggMy45MSA2LjE2Yy0uMTItLjEyLS4yNS0uMTctLjQxLS4xN3MtLjMuMDUtLjQxLjE3bC0uOTMuOTNjLS4xMi4xMi0uMTcuMjUtLjE3LjQxcy4wNS4zLjE3LjQxbDMuMjYgMy4yNmEuNTU0LjU1NCAwIDAwLjgyIDB6TTE0IDMuMTN2OC43NWMwIC43Mi0uMjYgMS4zNC0uNzcgMS44NXMtMS4xMy43Ny0xLjg2Ljc3SDIuNjNjLS43MyAwLTEuMzUtLjI2LTEuODYtLjc3QTIuNTMgMi41MyAwIDAxMCAxMS44N1YzLjEzYzAtLjczLjI2LTEuMzUuNzctMS44NkEyLjUzIDIuNTMgMCAwMTIuNjIuNWg4Ljc1Yy43MyAwIDEuMzUuMjYgMS44Ni43N1MxNCAyLjQgMTQgMy4xMnoiLz48L3N2Zz4=");
    }
    border: 2px solid hsla(0, 0%, 100%, 0.65);
    color: #fff;
  }
  .c-conversation-box-content-message-bubble__picker--has-selected {
    .c-conversation-box-content-message-bubble__choice {
      &:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTYuMjQgMTEuMTdsNS42LTUuNmMuMTItLjEuMTctLjI1LjE3LS40cy0uMDUtLjMtLjE3LS40MWwtLjkzLS45M2MtLjEyLS4xMi0uMjUtLjE4LS40MS0uMThzLS4zLjA2LS40MS4xOEw1LjgzIDguMDggMy45MSA2LjE2Yy0uMTItLjEyLS4yNS0uMTctLjQxLS4xN3MtLjMuMDUtLjQxLjE3bC0uOTMuOTNjLS4xMi4xMi0uMTcuMjUtLjE3LjQxcy4wNS4zLjE3LjQxbDMuMjYgMy4yNmEuNTU0LjU1NCAwIDAwLjgyIDB6TTE0IDMuMTN2OC43NWMwIC43Mi0uMjYgMS4zNC0uNzcgMS44NXMtMS4xMy43Ny0xLjg2Ljc3SDIuNjNjLS43MyAwLTEuMzUtLjI2LTEuODYtLjc3QTIuNTMgMi41MyAwIDAxMCAxMS44N1YzLjEzYzAtLjczLjI2LTEuMzUuNzctMS44NkEyLjUzIDIuNTMgMCAwMTIuNjIuNWg4Ljc1Yy43MyAwIDEuMzUuMjYgMS44Ni43N1MxNCAyLjQgMTQgMy4xMnoiLz48L3N2Zz4=");
      }
    }
    .c-conversation-box-content-message-bubble__choice--not-selected {
      &:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE1IiB3aWR0aD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBoZWlnaHQ9IjE1IiByeD0iMyIgd2lkdGg9IjE0Ii8+PC9zdmc+");
      }
      border-color: hsla(0, 0%, 100%, 0.3);
    }
  }
  .c-conversation-box-content-message-bubble__field--empty {
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE1IiB3aWR0aD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBoZWlnaHQ9IjE1IiByeD0iMyIgd2lkdGg9IjE0Ii8+PC9zdmc+");
    }
    border-color: hsla(0, 0%, 100%, 0.3);
    color: #fff;
  }
  .c-conversation-box-content-message-bubble__picker {
    .c-conversation-box-content-message-bubble__choice {
      border: 2px solid hsla(0, 0%, 100%, 0.65);
      color: #fff;
    }
  }
  .c-conversation-box-content-message-bubble__link {
    background-color: #fff;
    color: #000;
  }
  .c-conversation-box-content-message-bubble__preview {
    .c-conversation-box-content-message-bubble__preview-link {
      background-color: #fff;
      color: #000;
    }
  }
  .c-conversation-box-content-message-bubble__link[data-file="video"] {
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE1IiB3aWR0aD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkuNSA3LjVsLTkgN1YuNXoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      display: inline-block;
    }
  }
  .c-conversation-box-content-message-bubble__link[data-file="download"] {
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOCAxNXYzSDZ2LTNINHYzYzAgMS4xLjkgMiAyIDJoMTJjMS4xIDAgMi0uOSAyLTJ2LTN6bS0xLTRsLTEuNDEtMS40MUwxMyAxMi4xN1Y0aC0ydjguMTdMOC40MSA5LjU5IDcgMTFsNSA1eiIvPjwvc3ZnPg==");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      display: inline-block;
    }
  }
  .c-conversation-box-content-message-bubble__compose-animation {
    .c-conversation-box-content-message-bubble__dot {
      background: #fff;
    }
  }
  .c-conversation-box-content-message-bubble__compose-cursor {
    background: #fff;
  }
  .c-conversation-box-content-message-bubble__flag {
    right: -2px;
  }
  &.c-conversation-box-content-message-bubble--has-error {
    .c-conversation-box-content-message-bubble__wrapper {
      background-color: rgba(0, 77, 240, 0.2);
    }
  }
}

.c-conversation-box-content-message-bubble--animation {
  .c-conversation-box-content-message-bubble__image {
    width: 180px;
    height: 100px;
    position: relative;
    display: block;
    border-radius: 16px;
    background-color: #f8fafc;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    .c-conversation-box-content-message-bubble__source {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: none;
      height: 36px;
      border-bottom-right-radius: 14px;
      border-bottom-left-radius: 14px;
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(transparent),
        color-stop(33%, rgba(0, 0, 0, 0.1)),
        to(rgba(0, 0, 0, 0.5))
      );
      background-image: linear-gradient(
        -180deg,
        transparent,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.5)
      );
    }
    .c-conversation-box-content-message-bubble__source--watermark {
      position: absolute;
      bottom: 8px;
      left: 12px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      display: inline-block;
    }
    .c-conversation-box-content-message-bubble__source[data-source*="giphy.com"] {
      display: block;
      .c-conversation-box-content-message-bubble__source--watermark {
        width: 56px;
        height: 12px;
        background-image: url("@/assets/images/common/brands/giphy_white.png");
      }
    }
  }
  .c-conversation-box-content-message-bubble__wrapper {
    padding: 0;
  }
}

.c-conversation-box-content-message-bubble--picker {
  .c-conversation-box-content-message-bubble__picker {
    display: block;
    margin-top: 2px;
    padding: 10px 0 4px;
    .c-conversation-box-content-message-bubble__choice {
      display: block;
      margin: 6px auto 0;
      padding: 7px 15px;
      min-width: 100px;
      border-radius: 2px;
      text-align: center;
      font-size: 13px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  .c-conversation-box-content-message-bubble__picker--has-selected {
    .c-conversation-box-content-message-bubble__choice {
      text-align: left;
      &:before {
        display: inline-block;
        margin-top: -2px;
        margin-right: 9px;
        margin-left: -6px;
        width: 17px;
        height: 18px;
        background-size: cover;
        content: "";
        vertical-align: middle;
      }
    }
    .c-conversation-box-content-message-bubble__choice--not-selected {
      &:before {
        opacity: 0.25;
      }
    }
  }
  .c-conversation-box-content-message-bubble__picker[data-choices-count="2"] {
    padding-top: 0;
    text-align: center;
    .c-conversation-box-content-message-bubble__choice {
      display: inline-block;
      margin-right: 4px;
      margin-left: 4px;
      min-width: 80px;
      cursor: default;
    }
  }
  .c-conversation-box-content-message-bubble__picker[data-choices-count="2"]--has-selected {
    .c-conversation-box-content-message-bubble__choice {
      text-align: center;
    }
  }
}

.c-conversation-box-content-message-bubble--image {
  .c-conversation-box-content-message-bubble__wrapper {
    cursor: pointer;
  }
}

.c-conversation-box-content-message-bubble--event {
  .c-conversation-box-content-message-bubble__time {
    margin-top: 0;
  }
  .c-conversation-box-content-message-bubble__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #2e628a;
    color: #fff;
    font-size: 16px;
    line-height: 12px;
    gap: 12px;
  }
  .c-conversation-box-content-message-bubble__content {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow: visible;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    .c-conversation-box-content-message-bubble__icon {
      margin-right: 10px;
      min-width: 14px;
      min-height: 14px;
      width: 14px;
      height: 14px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      display: inline-block;
    }
    .c-conversation-box-content-message-bubble__text {
      margin-top: -1px;
      margin-bottom: -1px;
      margin-left: 11px;
      padding-top: 1px;
      padding-bottom: 1px;
      padding-left: 11px;
      max-width: 200px;
      border-left: 1px solid hsla(0, 0%, 100%, 0.125);
      color: hsla(0, 0%, 100%, 0.7);
      font-size: 13px;
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="session:set:routing"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwLjk1IDUuNVY3aDIuMjV2Mi4yNWgxLjVWN2gyLjI1VjUuNUgxNC43VjMuMjVoLTEuNVY1LjV6bS0uOTUtMkMxMCAxLjU2NiA4LjQzNCAwIDYuNSAwUzMgMS41NjYgMyAzLjUgNC41NjYgNyA2LjUgNyAxMCA1LjQzNCAxMCAzLjV6TTAgMTJ2MmgxM3YtMmMwLTIuNjYtNC4zMy00LTYuNS00UzAgOS4zNCAwIDEyeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="session:set:closed"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOSAxOEE5IDkgMCAxMTkgMGE5IDkgMCAwMTAgMTh6bS0xLjEtNy42MmwtMy0zLTEuNzcgMS43NyAzIDMgMS43NyAxLjc3IDEuNzctMS43NyA1LjMtNS4zLTEuNzctMS43N3oiLz48L3N2Zz4=");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="session:tag:added"] {
    .c-conversation-box-content-message-tag__item {
      color: rgb(189, 192, 196);
      border-left: 1px solid #e1e1e1;
      padding-left: 5px;
      margin-left: 5px;
    }
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuMzc1IDBDMS4zMzk0NyAwIDAuNSAwLjgzOTQ2NiAwLjUgMS44NzVWMi42MjVDMC41IDMuNjYwNTMgMS4zMzk0NyA0LjUgMi4zNzUgNC41SDE5LjYyNUMyMC42NjA1IDQuNSAyMS41IDMuNjYwNTMgMjEuNSAyLjYyNVYxLjg3NUMyMS41IDAuODM5NDY3IDIwLjY2MDUgMCAxOS42MjUgMEgyLjM3NVoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMi4wODY3OSA2TDIuNjI2NTcgMTUuMTc2MkMyLjcxOTg0IDE2Ljc2MTkgNC4wMzI5NiAxOCA1LjYyMTM5IDE4SDE2LjM3ODNDMTcuOTY2NyAxOCAxOS4yNzk5IDE2Ljc2MTkgMTkuMzczMSAxNS4xNzYyTDE5LjkxMjkgNkgyLjA4Njc5Wk0xMSA3LjVDMTEuNDE0MiA3LjUgMTEuNzUgNy44MzU3OSAxMS43NSA4LjI1VjEzLjE4OTNMMTMuNDY5NyAxMS40Njk3QzEzLjc2MjYgMTEuMTc2OCAxNC4yMzc0IDExLjE3NjggMTQuNTMwMyAxMS40Njk3QzE0LjgyMzIgMTEuNzYyNiAxNC44MjMyIDEyLjIzNzQgMTQuNTMwMyAxMi41MzAzTDExLjUzMDMgMTUuNTMwM0MxMS4yMzc0IDE1LjgyMzIgMTAuNzYyNiAxNS44MjMyIDEwLjQ2OTcgMTUuNTMwM0w3LjQ2OTY3IDEyLjUzMDNDNy4xNzY3OCAxMi4yMzc0IDcuMTc2NzggMTEuNzYyNiA3LjQ2OTY3IDExLjQ2OTdDNy43NjI1NiAxMS4xNzY4IDguMjM3NDQgMTEuMTc2OCA4LjUzMDMzIDExLjQ2OTdMMTAuMjUgMTMuMTg5M1Y4LjI1QzEwLjI1IDcuODM1NzkgMTAuNTg1OCA3LjUgMTEgNy41WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="session:tag:removed"] {
    .c-conversation-box-content-message-tag__item {
      color: rgb(189, 192, 196);
      border-left: 1px solid #e1e1e1;
      padding-left: 5px;
      margin-left: 5px;
    }
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuMzc1IDBDMS4zMzk0NyAwIDAuNSAwLjgzOTQ2NiAwLjUgMS44NzVWMi42MjVDMC41IDMuNjYwNTMgMS4zMzk0NyA0LjUgMi4zNzUgNC41SDE5LjYyNUMyMC42NjA1IDQuNSAyMS41IDMuNjYwNTMgMjEuNSAyLjYyNVYxLjg3NUMyMS41IDAuODM5NDY3IDIwLjY2MDUgMCAxOS42MjUgMEgyLjM3NVoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMi4wODY3OSA2TDIuNjI2NTcgMTUuMTc2MkMyLjcxOTg0IDE2Ljc2MTkgNC4wMzI5NiAxOCA1LjYyMTM5IDE4SDE2LjM3ODNDMTcuOTY2NyAxOCAxOS4yNzk5IDE2Ljc2MTkgMTkuMzczMSAxNS4xNzYyTDE5LjkxMjkgNkgyLjA4Njc5Wk04LjIxOTY3IDguODQ0NjdDOC41MTI1NiA4LjU1MTc4IDguOTg3NDQgOC41NTE3OCA5LjI4MDMzIDguODQ0NjdMMTEgMTAuNTY0M0wxMi43MTk3IDguODQ0NjdDMTMuMDEyNiA4LjU1MTc4IDEzLjQ4NzQgOC41NTE3OCAxMy43ODAzIDguODQ0NjdDMTQuMDczMiA5LjEzNzU2IDE0LjA3MzIgOS42MTI0NCAxMy43ODAzIDkuOTA1MzNMMTIuMDYwNyAxMS42MjVMMTMuNzgwMyAxMy4zNDQ3QzE0LjA3MzIgMTMuNjM3NiAxNC4wNzMyIDE0LjExMjQgMTMuNzgwMyAxNC40MDUzQzEzLjQ4NzQgMTQuNjk4MiAxMy4wMTI2IDE0LjY5ODIgMTIuNzE5NyAxNC40MDUzTDExIDEyLjY4NTdMOS4yODAzMyAxNC40MDUzQzguOTg3NDQgMTQuNjk4MiA4LjUxMjU2IDE0LjY5ODIgOC4yMTk2NyAxNC40MDUzQzcuOTI2NzggMTQuMTEyNCA3LjkyNjc4IDEzLjYzNzYgOC4yMTk2NyAxMy4zNDQ3TDkuOTM5MzQgMTEuNjI1TDguMjE5NjcgOS45MDUzM0M3LjkyNjc4IDkuNjEyNDQgNy45MjY3OCA5LjEzNzU2IDguMjE5NjcgOC44NDQ2N1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="session:call:ended"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTkgMThBOSA5IDAgMTA5IDBhOSA5IDAgMDAwIDE4em0uOTYtOS4wNGwtMy45LTMuOUw3Ljg1IDMuM2w1LjY1IDUuNjYtMS43NiAxLjc2LTMuOSAzLjktMS43Ni0xLjc3eiIvPjwvc3ZnPg==");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="session:set:change_type"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTkgMThBOSA5IDAgMTA5IDBhOSA5IDAgMDAwIDE4em0uOTYtOS4wNGwtMy45LTMuOUw3Ljg1IDMuM2w1LjY1IDUuNjYtMS43NiAxLjc2LTMuOSAzLjktMS43Ni0xLjc3eiIvPjwvc3ZnPg==");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="user:blocked"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOSAxOEE5IDkgMCAxMTkgMGE5IDkgMCAwMTAgMTh6bS4wMi0xMC43NGwtMy0zLjAxLTEuNzcgMS43NyAzIDMtMyAzIDEuNzcgMS43OCAzLTMgMyAzIDEuNzgtMS43Ny0zLTMgMy0zLjAxLTEuNzctMS43N3oiLz48L3N2Zz4=");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="reminder:scheduled"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEyLjc3IDQuNjlILjM1QS4zNS4zNSAwIDAxMCA0LjM0VjMuMjhjMC0uNzguNjMtMS40IDEuNC0xLjRoMS40MVYuMzRjMC0uMi4xNi0uMzUuMzUtLjM1aDEuMThjLjE5IDAgLjM1LjE2LjM1LjM1djEuNTJoMy43NVYuMzVjMC0uMi4xNi0uMzUuMzUtLjM1aDEuMTdjLjIgMCAuMzUuMTYuMzUuMzV2MS41MmgxLjRjLjc5IDAgMS40MS42MyAxLjQxIDEuNDF2MS4wNmMwIC4xOS0uMTUuMzUtLjM1LjM1ek0uMzUgNS42MmgxMi40MmMuMiAwIC4zNi4xNi4zNi4zNnY3LjYxYzAgLjc4LS42MyAxLjQxLTEuNDEgMS40MUgxLjRBMS40IDEuNCAwIDAxMCAxMy42VjUuOTdjMC0uMi4xNi0uMzYuMzUtLjM2em0zLjQgNS45OGMwLS4yLS4xNi0uMzUtLjM1LS4zNUgyLjIzYy0uMiAwLS4zNS4xNi0uMzUuMzV2MS4xN2MwIC4yLjE1LjM2LjM1LjM2SDMuNGMuMiAwIC4zNS0uMTYuMzUtLjM2em0wLTMuNzVjMC0uMi0uMTYtLjM1LS4zNS0uMzVIMi4yM2MtLjIgMC0uMzUuMTYtLjM1LjM1djEuMTdjMCAuMi4xNS4zNi4zNS4zNkgzLjRjLjIgMCAuMzUtLjE2LjM1LS4zNnpNNy41IDExLjZjMC0uMi0uMTYtLjM1LS4zNS0uMzVINS45OGMtLjIgMC0uMzYuMTYtLjM2LjM1djEuMTdjMCAuMi4xNi4zNi4zNi4zNmgxLjE3Yy4yIDAgLjM1LS4xNi4zNS0uMzZ6bTAtMy43NWMwLS4yLS4xNi0uMzUtLjM1LS4zNUg1Ljk4Yy0uMiAwLS4zNi4xNi0uMzYuMzV2MS4xN2MwIC4yLjE2LjM2LjM2LjM2aDEuMTdjLjIgMCAuMzUtLjE2LjM1LS4zNnptMy43NSAzLjc1YzAtLjItLjE2LS4zNS0uMzUtLjM1SDkuNzNjLS4yIDAtLjM2LjE2LS4zNi4zNXYxLjE3YzAgLjIuMTYuMzYuMzYuMzZoMS4xN2MuMiAwIC4zNS0uMTYuMzUtLjM2em0wLTMuNzVjMC0uMi0uMTYtLjM1LS4zNS0uMzVIOS43M2MtLjIgMC0uMzYuMTYtLjM2LjM1djEuMTdjMCAuMi4xNi4zNi4zNi4zNmgxLjE3Yy4yIDAgLjM1LS4xNi4zNS0uMzZ6Ii8+PC9zdmc+");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="thread:started"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTkgMThBOSA5IDAgMTA5IDBhOSA5IDAgMDAwIDE4em0uOTYtOS4wNGwtMy45LTMuOUw3Ljg1IDMuM2w1LjY1IDUuNjYtMS43NiAxLjc2LTMuOSAzLjktMS43Ni0xLjc3eiIvPjwvc3ZnPg==");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="thread:ended"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTkgMThBOSA5IDAgMTE5IDBhOSA5IDAgMDEwIDE4em0tLjk2LTkuMDRsMy44OC0zLjktMS43Ni0xLjc2TDQuNSA4Ljk2bDEuNzcgMS43NiAzLjg5IDMuOSAxLjc2LTEuNzd6Ii8+PC9zdmc+");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="participant:added"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwLjk1IDUuNVY3aDIuMjV2Mi4yNWgxLjVWN2gyLjI1VjUuNUgxNC43VjMuMjVoLTEuNVY1LjV6bS0uOTUtMkMxMCAxLjU2NiA4LjQzNCAwIDYuNSAwUzMgMS41NjYgMyAzLjUgNC41NjYgNyA2LjUgNyAxMCA1LjQzNCAxMCAzLjV6TTAgMTJ2MmgxM3YtMmMwLTIuNjYtNC4zMy00LTYuNS00UzAgOS4zNCAwIDEyeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="participant:removed"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwIDMuNUMxMCAxLjU2NiA4LjQzNCAwIDYuNSAwUzMgMS41NjYgMyAzLjUgNC41NjYgNyA2LjUgNyAxMCA1LjQzNCAxMCAzLjV6bTEuNDUgMnYxLjYxOWg1LjQ4VjUuNXpNMCAxMnYyaDEzdi0yYzAtMi42Ni00LjMzLTQtNi41LTRTMCA5LjM0IDAgMTJ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="call:started"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxMSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE1LjQuODJ2OS4zNmMwIC4yNC0uMTEuNC0uMzQuNWEuNi42IDAgMDEtLjIxLjA0LjUxLjUxIDAgMDEtLjM5LS4xNkwxMSA3LjF2MS40MmMwIC42OS0uMjQgMS4yNy0uNzMgMS43NS0uNDguNDktMS4wNi43My0xLjc1LjczSDIuNDdjLS42OCAwLTEuMjYtLjI0LTEuNzQtLjczQTIuMzggMi4zOCAwIDAxMCA4LjUyVjIuNDdDMCAxLjguMjQgMS4yMS43My43M0EyLjM4IDIuMzggMCAwMTIuNDcgMGg2LjA1Yy42OSAwIDEuMjcuMjQgMS43NS43My40OS40OC43MyAxLjA2LjczIDEuNzRWMy45TDE0LjQ2LjQ0Yy4xLS4xMS4yNC0uMTcuMzktLjE3YS42LjYgMCAwMS4yMS4wNWMuMjMuMS4zNC4yNi4zNC41eiIvPjwvc3ZnPg==");
    }
  }
  .c-conversation-box-content-message-bubble__content[data-event="call:ended"] {
    .c-conversation-box-content-message-bubble__icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNCI+PGcgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0xLjg1IDExLjA4bDkuNTQtOS41NGEyLjM4IDIuMzggMCAwMS44MyAxLjg1VjQuOGwzLjQ2LTMuNDZjLjEtLjEuMjMtLjE2LjM4LS4xNmEuNi42IDAgMDEuMjIuMDRjLjIyLjEuMzMuMjcuMzMuNTF2OS4zNGMwIC4yNC0uMTEuNDEtLjMzLjUxYS42LjYgMCAwMS0uMjIuMDQuNTEuNTEgMCAwMS0uMzgtLjE2TDEyLjIgOC4wMXYxLjQzYzAgLjY4LS4yNCAxLjI2LS43MiAxLjc0LS40OS40OS0xLjA3LjczLTEuNzUuNzNIMy43YTIuMzggMi4zOCAwIDAxLTEuODQtLjgzem0tLjYzLTEuOTZWMy4zOWMwLS42OC4yNC0xLjI2LjczLTEuNzVTMyAuOTIgMy42OS45Mmg1LjczeiIvPjxyZWN0IHdpZHRoPSIxNy41NTgiIGhlaWdodD0iMS44MzIiIHg9Ii0xLjQzNSIgeT0iNS45NzEiIHJ4PSIuNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTQ1IDcuMzQ0IDYuODg3KSIvPjwvZz48L3N2Zz4=");
    }
  }
}

.c-conversation-box-content-message-bubble--field {
  min-width: 284px;
  .c-conversation-box-content-message-bubble__field {
    display: block;
    margin-top: 9px;
    margin-bottom: 4px;
    padding: 0 4px 0 15px;
    border-radius: 2px;
    letter-spacing: 0.1px;
    font-size: 13px;
    line-height: 31px;
    &:before {
      display: inline-block;
      margin-top: -2px;
      margin-right: 9px;
      width: 17px;
      height: 18px;
      background-size: cover;
      content: "";
      vertical-align: middle;
    }
  }
  .c-conversation-box-content-message-bubble__field--empty {
    font-style: italic;
    &:before {
      opacity: 0.25;
    }
  }
}

.c-conversation-box-content-message-bubble--file {
  &.c-conversation-box-content-message-bubble--file-caption {
    .c-conversation-box-content-message-bubble__time {
      margin-left: 0;
    }

    .c-conversation-box-content-message-bubble__wrapper {
      text-align: left;
      background-color: rgba(117, 117, 118, 0.2);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .c-conversation-box-content-message-bubble__time {
    margin-left: 12px;
  }

  .c-conversation-box-content-message-bubble__wrapper {
    text-align: center;
    background-color: unset;
    box-shadow: unset;
    padding: 8px;
  }

  .c-conversation-box-content-message-bubble__image {
    position: relative;
    display: block;
    border-radius: 16px;
    background-color: #f8fafc;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 280px;
    max-width: 600px;
    width: 100%;
    height: 180px;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
  .c-conversation-box-content-message-bubble__content {
    display: block;
    padding: 8px 20px 7px;
    font-size: 13px;
  }
  .c-conversation-box-content-message-bubble__link {
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 10px;
    margin-left: 12px;
    margin: 0;
    padding: 0 16px;
    height: 31px;
    border-radius: 100px;
    text-align: center;
    word-break: break-all;
    font-size: 12.5px;
    line-height: 31px;
    cursor: pointer;
    position: relative;
    &:hover {
      text-decoration: underline;
    }
  }
  .c-conversation-box-content-message-bubble__link[data-file="video"] {
    padding-left: 30px;
    &:before {
      content: "";
      height: 14px;
      width: 14px;
      position: absolute;
      top: 8px;
      left: 11px;
    }
  }
  .c-conversation-box-content-message-bubble__link[data-file="download"] {
    padding-left: 36px;
    &:before {
      content: "";
      height: 18px;
      width: 18px;
      position: absolute;
      top: 7px;
      left: 13px;
    }
  }
}

.c-conversation-box-content-message-bubble--audio {
  .c-conversation-box-content-message-bubble__wrapper {
    overflow: hidden;
  }
  .c-conversation-box-content-message-bubble__audio {
    display: block;
    .c-conversation-box-content-message-bubble__media {
      display: none;
    }
    .c-conversation-box-content-message-bubble__jump {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      min-width: 0;
      max-width: 100%;
      width: 0;
      background-color: hsla(0, 0%, 100%, 0.2);
    }
    .c-conversation-box-content-message-bubble__progress {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      min-width: 0;
      max-width: 100%;
      width: 0;
      -webkit-transition: width 0.5s linear;
      transition: width 0.5s linear;
      background-color: #0078d1;
    }
    .c-conversation-box-content-message-bubble__wrapped {
      position: relative;
      z-index: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      .c-conversation-box-content-message-bubble__duration {
        z-index: 2;
        margin: 1px 0 -1px;
        letter-spacing: 0.6px;
        font-size: 13px;
        color: #fff;
      }
      .c-conversation-box-content-message-bubble__icon {
        z-index: 2;
        margin: -4px -8px;
        width: 26px;
        height: 26px;
        border-radius: 200px;
        text-align: center;
        -webkit-transition: background-color 0.1s linear;
        transition: background-color 0.1s linear;
        cursor: pointer;
        &:before {
          margin-top: 6px;
          width: 10px;
          height: 14px;
          content: "";
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOS41IDcuNWwtOSA3Vi41eiIvPjwvc3ZnPg==");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50%;
          display: inline-block;
        }
        &:hover {
          background-color: hsla(0, 0%, 100%, 0.1);
        }
        &:active {
          background-color: hsla(0, 0%, 100%, 0.2);
        }
      }
      .c-conversation-box-content-message-bubble__row {
        z-index: 2;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 8px 10px 0;
        width: 86px;
        height: 2px;
        background-color: #fff;
      }
    }
  }
  .c-conversation-box-content-message-bubble__audio[data-state="loading"] {
    .c-conversation-box-content-message-bubble__icon {
      &:before {
        -webkit-animation-name: c-conversation-box-content-message-bubble-audio-icon-loading;
        animation-name: c-conversation-box-content-message-bubble-audio-icon-loading;
        -webkit-animation-duration: 1.25s;
        animation-duration: 1.25s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
      }
    }
  }
  .c-conversation-box-content-message-bubble__audio[data-state="playing"] {
    .c-conversation-box-content-message-bubble__wrapped {
      .c-conversation-box-content-message-bubble__icon {
        &:before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEzIiB3aWR0aD0iOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAuNWgzdjEySDB6bTUuNSAwaDN2MTJoLTN6IiBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50%;
          display: inline-block;
        }
      }
    }
  }
}

.c-conversation-box-content-message-bubble--note {
  max-width: 500px;
  .c-conversation-box-content-message-bubble__wrapper {
    position: relative;
    padding: 13px 22px;
    background-color: #fff5cc;
    color: #000;
    &:before {
      position: absolute;
      top: 8px;
      left: -16px;
      width: 28px;
      height: 28px;
      border: 3px solid #eff3f6;
      border-radius: 28px;
      content: "";
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PGcgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiNmYWNhMDAiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNOC41IDYuNWg3Yy4yOCAwIC41LjIyLjUuNXYxMC41N2EuNS41IDAgMDEtLjg4LjMzbC0yLjcyLTMuMTdhLjUuNSAwIDAwLS43NiAwTDguODggMTcuOWEuNS41IDAgMDEtLjg4LS4zM1Y3YzAtLjI4LjIyLS41LjUtLjV6Ii8+PC9nPjwvc3ZnPg==");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      display: inline-block;
    }
  }
  .c-conversation-box-content-message-bubble__note-header {
    display: block;
    margin-bottom: 8px;
    text-decoration: underline;
    font-size: 14px;
  }
  .c-conversation-box-content-message-bubble__content {
    .o-link {
      color: #000;
      &:hover {
        color: #000;
      }
    }
    .o-markdown {
      color: #000;
      &:hover {
        color: #000;
      }
    }
    .o-markdown.o-markdown-line {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}

.c-conversation-box-content-message-bubble--no-shadow {
  .c-conversation-box-content-message-bubble__wrapper {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.c-conversation-box-content-message-bubble--expand {
  width: 100%;
  max-width: 1000px;
}

.c-conversation-box-content-message-bubble--compose {
  .c-conversation-box-content-message-bubble__compose-cursor {
    display: inline-block;
    margin-top: -4px;
    margin-left: 2px;
    width: 2px;
    height: 18px;
    vertical-align: middle;
    -webkit-animation-name: blink;
    animation-name: blink;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  .c-conversation-box-content-message-bubble__compose-animation {
    .c-conversation-box-content-message-bubble__dot {
      display: inline-block;
      margin-right: 1px;
      margin-left: 1px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      -webkit-animation-name: compose;
      animation-name: compose;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      &:first-child {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        -webkit-animation-delay: -0.25s;
        animation-delay: -0.25s;
      }
      &:nth-child(3) {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }
    }
  }
  .c-conversation-box-content-message-bubble__content {
    .o-smiley[data-size="large"] {
      display: inline-block;
      margin-top: 1px;
      margin-bottom: -5px;
    }
  }
  .c-base-tooltip[data-align="left"] {
    &:after {
      margin-left: 15px;
    }
  }
  .c-base-tooltip[data-align="right"] {
    &:after {
      margin-right: 15px;
    }
  }
  .c-conversation-box-content-message-bubble__wrapper {
    opacity: 0.75 !important;
  }
  &:active {
    .c-conversation-box-content-message-bubble__wrapper {
      opacity: 0.75 !important;
    }
  }
  &.c-conversation-box-content-message-bubble--has-content {
    .c-conversation-box-content-message-bubble__wrapper {
      padding-right: 21px;
    }
  }
}

.c-conversation-box-content-message-bubble--compose-preview {
  .c-conversation-box-content-message-bubble__compose-cursor {
    display: inline-block;
    margin-top: -4px;
    margin-left: 2px;
    width: 2px;
    height: 18px;
    vertical-align: middle;
    -webkit-animation-name: blink;
    animation-name: blink;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  .c-conversation-box-content-message-bubble__compose-animation {
    .c-conversation-box-content-message-bubble__dot {
      display: inline-block;
      margin-right: 1px;
      margin-left: 1px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      -webkit-animation-name: compose;
      animation-name: compose;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      &:first-child {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        -webkit-animation-delay: -0.25s;
        animation-delay: -0.25s;
      }
      &:nth-child(3) {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }
    }
  }
  .c-conversation-box-content-message-bubble__content {
    .o-smiley[data-size="large"] {
      display: inline-block;
      margin-top: 1px;
      margin-bottom: -5px;
    }
  }
  .c-base-tooltip[data-align="left"] {
    &:after {
      margin-left: 15px;
    }
  }
  .c-base-tooltip[data-align="right"] {
    &:after {
      margin-right: 15px;
    }
  }
  .c-conversation-box-content-message-bubble__wrapper {
    opacity: 0.75 !important;
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 3px 9px;
    border-radius: 100px;
    background-color: #e0e5e8;
    color: #000;
    -webkit-hyphens: auto;
    hyphens: auto;
    .c-conversation-box-content-message-bubble__content--compose-is-cut {
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 60px;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        background-image: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(12%, #e8ebef),
          to(rgba(232, 235, 239, 0))
        );
        background-image: linear-gradient(
          90deg,
          #e8ebef 12%,
          rgba(232, 235, 239, 0)
        );
        content: "";
      }
    }
  }
  &:active {
    .c-conversation-box-content-message-bubble__wrapper {
      opacity: 0.75 !important;
    }
  }
  &.c-conversation-box-content-message-bubble--has-content {
    .c-conversation-box-content-message-bubble__wrapper {
      padding-right: 14px;
    }
  }
}
</style>
